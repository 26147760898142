import axios from "axios";
import apiconfig from "../config/config";
import { useSelector } from "react-redux";

const avdurl1 = apiconfig.develpoment.apiBaseUrl;
const api = axios.create({
    baseURL: avdurl1,
});
const jwttoken = localStorage.getItem('token')

const setAuthToken = (token) => {
    if (token) {
        api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
};


const login = (body) => {
    return api.post('signup/login', body)
}

const signup = (body) => {
    return api.post('signup/addsignupdetails', body)
}


const forgotPassword = (body) => {
    return api.post('signup/forgot-password', body)
}


const updatePassword = (body) => {
    return api.post('signup/update-password', body)
}

const getEvents = (lati, long) => {
    return api.get(`events/webcombinedEvents?lat=${Number(lati)}&long=${Number(long)}`)

}

const getEventReviews = (body) => {
    return api.get(`events/webgetEventreviews/${body}`)
}


const getEvent = (body) => {
    return api.get(`events/webgetEventbyId/${body}`)
}


const sendDataTobackend = (body) => {
    return api.post('events/qr-codes', body, {
        headers: {
            Authorization: `Bearer ${jwttoken}`,
        }
    }
    )



}
const pay = (body) => {
    return api.put('events/payForEvents', body, {
        headers: {
            Authorization: `Bearer ${jwttoken}`,
        }
    })
}
const bookTicket = (body) => {
    return api.post('events/ticket-inrement', body, {
        headers: {
            Authorization: `Bearer ${jwttoken}`,
        }
    })
}
const reservationPoints = (body) => {
    return api.post('signup/reservationPoints-increment', body, {
        headers: {
            Authorization: `Bearer ${jwttoken}`,
        }
    })
}

const addFavuorite = (body) => {
    return api.post('events/addFavourites', body
    )
}

const checkFavuorite = (body) => {
    return api.post('events/checkFavourites', body)
}

const removeFavuorite = (body) => {
    return api.post(`events/removeFavourites`, body
    )
}

const refresh = (body) => {
    return api.post('signup/refresh', body)
}

const favs = (body) => {
    return api.post('events/Favourites', body)
}
const APIServices = {
    setAuthToken, login, signup, forgotPassword, updatePassword, getEvents, getEventReviews, getEvent, sendDataTobackend, pay, reservationPoints, bookTicket, addFavuorite, removeFavuorite, refresh, checkFavuorite, favs
}

export default APIServices



