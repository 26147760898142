import { actionTypes, setUserLocation } from "./Actions";

const initialState = {
  accessToken: null,
  refreshToken: null,
  userDetails: null,
  userLong: null,
  userLat: null,
  eventData: null,
  userLocation: null,
  ticketDetails: null,
  amountBody: null
};

export const reducerFunction = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.setAccessToken: {
      return { ...state, accessToken: action.payload };
    }
    case actionTypes.setRefreshToken: {
      return { ...state, refreshToken: action.payload };
    }
    case actionTypes.setUserDetails: {
      return { ...state, userDetails: action.payload };
    }
    case actionTypes.setUserLat: {
      return { ...state, userLat: action.payload };
    }
    case actionTypes.setUserLong: {
      return { ...state, userLong: action.payload };
    }
    case actionTypes.setEventData: {
      return { ...state, eventData: action.payload };
    }
    case actionTypes.setUserLocation: {
      return { ...state, userLocation: action.payload };
    }
    case actionTypes.setTicketDetails: {
      return { ...state, ticketDetails: action.payload };
    }
    case actionTypes.setAmountBody: {
      return { ...state, amountBody: action.payload };
    }
    default:
      return state;
  }
};