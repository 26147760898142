import React from "react";

const ViewEvent = () => {
    return (
        <div className="view-bg-container">
            <h1>
                This Home Page
            </h1>
        </div>
    )
}

export default ViewEvent