import React, { useEffect, useState } from 'react';
import './checkout.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from 'react-redux';
import { useElements, useStripe, CardElement } from '@stripe/react-stripe-js';
import { UseSelector } from 'react-redux';
import { setAmountBody } from '../../Redux/Actions';

const Checkout = () => {
  const options = {
    // clientSecret: 'sk_test_51Pacl7D14xfJoKMAiF37OBzmxP0csrbbh9r3OPtqxy6zKrnF3aWMNMoA8n4PqT5wM1IUyGw7jDBfkBCyQsBg77ac00nV6Nmtk6',
    clientSecret: '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'
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const { state } = useLocation();
  const formData = state.formData[0]
  // const [eventData, setEventData] = useState(null);
  const [imageUrls, setImageUrls] = useState([])
  console.log(formData)
  const userDetails = useSelector((state) => {
    return state.userDetails;
  });

  const eventData = useSelector((state) => {
    return state.eventData;
  });

  const [loading, setLoading] = useState(false);


  const eventDetails = useSelector((state) => {
    return state.eventData;
  });


  useEffect(() => {
    setLoading(true)
    // const event = localStorage.getItem('event');
    const event = eventData;
    if (event) {
      // const parsedEvent = JSON.parse(event);
      // console.log(parsedEvent)
      // setEventData(parsedEvent);
      if (eventData.allimages && Array.isArray(eventData.allimages)) {
        const filteredImages = eventData.allimages.filter((url) => {
          return url.match(/\.(jpeg|jpg|gif|png|webp)$/);
        });
        setImageUrls(filteredImages);
      }
    }
    setLoading(false)
  }, []);

  const convertDateFormat = (inputDate) => {
    // Split the input date into day, month, and year
    const [day, month, year] = inputDate.split('-');

    // Create a new Date object using the extracted values
    const date = new Date(`${year}-${month}-${day}`);

    // Get the day of the week (short form)
    const options = { weekday: 'short' };
    const dayOfWeek = new Intl.DateTimeFormat('en-US', options).format(date);

    // Get the month name (long form)
    const monthName = date.toLocaleString('default', { month: 'long' });

    // Format the final output as "Sat 27, July"
    return `${dayOfWeek} ${day}, ${monthName}`;
  };
  function convertTo12HourFormat(time24) {
    const [hours, minutes] = time24.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const adjustedHours = hours % 12 || 12; // Convert 0 to 12 for midnight
    return `${adjustedHours}:${minutes.toString().padStart(2, '0')} ${period}`;
  }

  const getEventPrice = () => {
    let itemtotal = 0;

    if (formData?.skipLinePrice) {
      itemtotal = formData?.skipLinePrice;
    } else if (formData?.bookSectionPrice) {
      itemtotal = formData?.bookSectionPrice;
    } else {
      itemtotal = state.formData.length * formData?.price;
    }

    const total = itemtotal;
    const platformfee = itemtotal * 0.08;
    const totalAmt = Number(itemtotal) + Number(platformfee);

    return { total, platformfee, totalAmt };
  };


  // const handleClick = async () => {
  //   setLoading(true)
  //   // const stripePromise = await loadStripe('pk_test_51Pacl7D14xfJoKMAjaiJywsdOO811qnPzP7W47r0kW08u3cQJIG8kbd0OZfz7nbaL2ZMycR5jmuL6baxhRVgx4tA00OWNiJXQ7');
  //   const stripePromise = await loadStripe('pk_live_51Pacl7D14xfJoKMAyAXm704bMchgYbpfbJnKtybaf5MdcTIIzf6SdIiNlvXnyoeiYbRNZPcIx6ZpAAbN6GnLR0W700ttb65G8L')
  //   const body = {
  //     name: userDetails?.Fullname,
  //     email: userDetails?.Email,
  //     amount: getEventPrice().totalAmt,
  //     currency: 'usd',
  //     destination: eventDetails.stripeId,
  //     quantity: state.formData.length
  //   };
  //   console.log('Request body:', body);

  //   localStorage.setItem("amountBody", JSON.stringify(body))

  //   try {
  //     const checkoutResponse = await fetch('https://vipmeapp.com/advertisement/create-checkout-session', {
  //       method: 'POST',
  //       headers: {
  //         'Access-Control-Allow-Origin': '*',
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(body),
  //     });
  //     if (!checkoutResponse.ok) {
  //       setLoading(false)
  //       console.log(checkoutResponse)
  //       throw new Error('Checkout session API call failed.');
  //     }
  //     setLoading(false)
  //     const checkoutSession = await checkoutResponse.json();
  //     console.log('Checkout session:', checkoutSession);

  //     // Redirect to Stripe Checkout using the session ID
  //     const result = await stripePromise.redirectToCheckout({
  //       sessionId: checkoutSession.id,
  //     });
  //     localStorage.setItem('result', JSON.stringify(result))
  //     if (result.error) {
  //       setLoading(false)
  //       setError(error.response.data.error);
  //       console.error('Error during redirect to Stripe Checkout:', result.error);
  //     }
  //   } catch (error) {
  //     setLoading(false)
  //     setError('An unexpected error occurred. Please try again later.');
  //   }
  // };



  const handleClick = async () => {
    setLoading(true);

    // Initialize Stripe with your public key
    const stripe = await loadStripe('pk_live_51Pacl7D14xfJoKMAyAXm704bMchgYbpfbJnKtybaf5MdcTIIzf6SdIiNlvXnyoeiYbRNZPcIx6ZpAAbN6GnLR0W700ttb65G8L');

    const body = {
      name: userDetails?.Fullname,
      email: userDetails?.Email,
      amount: getEventPrice().totalAmt,
      currency: 'usd',
      destination: eventDetails.stripeId,
      quantity: state.formData.length
    };

    console.log('Request body:', body);
    dispatch(setAmountBody(body))
    // localStorage.setItem("amountBody", JSON.stringify(body));

    try {
      const checkoutResponse = await fetch('https://vipmeapp.com/advertisement/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*', // Note: CORS issues are best resolved on the server-side
        },
        body: JSON.stringify(body),
      });

      if (!checkoutResponse.ok) {
        const errorData = await checkoutResponse.json();
        throw new Error(errorData.error || 'Checkout session API call failed.');
      }

      const checkoutSession = await checkoutResponse.json();
      console.log('Checkout session:', checkoutSession);

      // Redirect to Stripe Checkout using the session ID
      const result = await stripe.redirectToCheckout({
        sessionId: checkoutSession.id,
      });

      if (result.error) {
        // Handle the error from Stripe Checkout
        console.error('Error during redirect to Stripe Checkout:', result.error);
        setError(result.error.message || 'An unexpected error occurred during payment. Please try again.');
      }

    } catch (error) {
      console.error('Error in handleClick:', error);
      setError(error.message || 'An unexpected error occurred. Please try again later.');
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="checkout-container">
      <Backdrop
        sx={{
          color: "#ffffff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="contact-info">
        <h2>Contact info</h2>
        <div className="phone-number">
          <input type="tel" placeholder="Phone number" value={userDetails?.mobile} className='checkout-input' />
        </div>
        <input type="text" placeholder="Full name" value={userDetails?.Fullname} className='checkout-input' />
        <input type="email" placeholder="Email" value={userDetails?.Email} className='checkout-input' />
      </div>
      <div className="appointment-summary">
        <h2>Ticket summary</h2>
        <div className="summary-item">
          <div className="item-details">
            <span className="date">{convertDateFormat(formData?.startDate)} </span><br></br>
            <span className="time">{convertTo12HourFormat(formData?.startTime)} </span>-&nbsp;
            <span className="due">{convertTo12HourFormat(formData?.endTime)}</span>
          </div>
        </div>
        <div className="cost-summary">
          <div className="cost-item">
            <span>Event name</span>
            <span>${" "}{formData.eventName}</span>
          </div>

          {formData.skipLinePrice && (
            <div className="cost-item">
              <span>Skipline price</span>
              <span>${" "}{formData.skipLinePrice}</span>
            </div>
          )}

          {formData.bookSectionPrice && (
            <div className="cost-item">
              <span>Book Section price</span>
              <span>${" "}{formData.bookSectionPrice}</span>
            </div>
          )}

          {/* {!shouldHide && (
    <div className="cost-item">
      <span>Ticket price</span>
      <span>${" "}{formData.price}</span>
    </div>
  )} */}

          <div className="cost-item">
            <span>No.of Tickets</span>
            <span>{state?.formData?.length}</span>
          </div>


        </div>



        <div className='divider'></div>
        <div className="cost-summary">
          <div className="cost-item">
            <span>Item total</span>
            <span>${" "}{getEventPrice().total}</span>
          </div>
          <div className="cost-item">
            <span>Service fee (8%)</span>
            <span>${" "}{getEventPrice().platformfee}</span>
          </div>
          <div className="cost-item total">
            <span>Total amount</span>
            <span>${" "}{getEventPrice().totalAmt}</span>
          </div>
        </div>
        {/* <CardElement /> */}
        {/* <Elements stripe={stripePromise} options={options}>
    </Elements> */}
        <button className="book-appointment" onClick={handleClick}>Book Event</button>
        {error && <div className="error-message">{error}</div>}
      </div>
    </div >
  );
};

export default Checkout;
