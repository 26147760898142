import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Divider } from "@mui/material";
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
// import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import { useNavigate, useLocation } from "react-router-dom";
import { UseDispatch } from "react-redux";
import { useNavigation } from "react-router-dom";
import "./Header.css";
import SearchIcon from '@mui/icons-material/Search';
import { setUserDetails, setEventData, setAccessToken, setRefreshToken, setUserLat, setUserLong, setUserLocation } from "../../Redux/Actions";
import { MenuList } from "@mui/material";
import {
    AccountCircle,
    Close,
    ArrowOutward,
    NotificationsNone,
    Add,
    PermIdentity,
    HelpOutline,
    Logout,
    NotificationsActiveOutlined
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import ButtonCompo from "../ButtonComponent/ButtonComponent";
// import {
//   setAccessToken,
//   setCountrySearch,
//   setEventDetails,
//   setEventDetailsPostObjectBody,
//   setRefreshToken,
//   setSelectedPlaceCoordinates,
//   setUserDetails,
//   setNotifications
// } from "../../Redux/Actions";
// import APIServices from "../../services/APIServices";
// import DialogLogoutComponent from "../Dialog/LogoutDialog";
// import Backdrop from "@mui/material/Backdrop";
// import CircularProgress from "@mui/material/CircularProgress";
// import AlertComponent from "../Alert/Alert";
// import moment from "moment-timezone";
// import "moment-timezone/data/packed/latest.json";

function Header({
    setShowProfile,
    setShowEditProfile,
    setShowRewards,
    setShowAccount,
    notificationsLength,
    notifications,
    setNotificationsLength
}) {
    // const [anchorElNav, setAnchorElNav] = React.useState(null);
    // const [anchorElUser, setAnchorElUser] = React.useState(null);
    // const location = useLocation();
    // const navigate = useNavigate();
    // const dispatch = useDispatch();
    // const [showPopUp, setShowPopUp] = React.useState(false);
    // const [showLoader, setShowLoader] = React.useState(false);
    // const [errorAlert, setErrorAlert] = React.useState(false);


    // const userMyOrganisedTrips = userDetails?.my_organized_trips;
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleLoginButton = () => {
        navigate('/login');
    }

    const isLoggedIn = localStorage.getItem("isLoggedIn");
    const handleLogout = () => {
        // Prompt the user for confirmation
        const confirmLogout = window.confirm("Are you sure you want to log out?");

        // If user confirms, proceed with logout
        if (confirmLogout) {
            localStorage.clear();
            dispatch(setAccessToken(null));
            dispatch(setRefreshToken(null));
            dispatch(setUserDetails(null));
            dispatch(setEventData(null));
            dispatch(setUserLat(null));
            dispatch(setUserLong(null));
            dispatch(setUserLocation(null));
            navigate('/login');
        } else {
            // Optionally handle cancellation if needed
            console.log("Logout cancelled");
        }
    }



    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuCloseFac = () => {
        setAnchorEl(null);
        navigate('/Favourite')
    }

    const handleMenuCloseFavs = () => {

        navigate('/Favourite')
    }
    const handleMenuCloses = () => {
        setAnchorEl(null);
        handleLogout()
    };
    const searchEvents = () => {
        navigate('/eventsearch')
    }
    return (
        <AppBar
            position="fixed"
            className="header-color"
            sx={{ backgroundColor: "#15151A" }}
        >
            <div className="header-contain">
                <Toolbar disableGutters className="nav-bar-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="app-name-container">
                        <div className="logo-name">
                            <img src="/logo.jpg" className="logoimage" />
                        </div>
                        <div className="app-name">
                            <img src="/eventsvipme.png" className="appImage" />
                        </div>
                        <div className="searchesevent" onClick={searchEvents}><span > <SearchIcon
                            className="search-iconhome"
                        /></span></div>
                    </div>
                    <div className="header-main-container">
                        <div className="menu-container">
                            {isLoggedIn ? (
                                <h5 className="header-menu-item" onClick={handleMenuCloseFavs}>
                                    Favourites
                                </h5>
                            ) : null}
                            {isLoggedIn ? (
                                <Button
                                    variant="outlined"
                                    size="small"
                                    className="hot-new-button"
                                    style={{ marginLeft: '80px' }}
                                    onClick={handleLogout}
                                >
                                    Log out
                                </Button>
                            ) : (
                                <Button
                                    variant="outlined"
                                    size="small"
                                    className="hot-new-button"
                                    onClick={handleLoginButton}
                                >
                                    Login
                                </Button>
                            )}
                        </div>
                        <div className="menu-container">
                            <a href="https://www.instagram.com/vip.meapp/" style={{ color: "white" }} target="_blank" rel="noopener noreferrer">
                                <InstagramIcon />
                            </a>&nbsp;&nbsp;
                            <div >
                                <a href="https://www.tiktok.com/@vip.meapp" target="_blank" rel="noopener noreferrer">
                                    <img
                                        src="https://store-images.s-microsoft.com/image/apps.3793.13634052595610511.c45457c9-b4af-46b0-8e61-8d7c0aec3f56.bbc8b3d1-941b-42c2-a610-e100e2aae247"
                                        style={{ width: '35px', height: 'auto' }}
                                        alt="TikTok Profile"
                                    />
                                </a>
                            </div>

                        </div>
                    </div>
                    {/* Mobile View */}
                    <div className="mobile-menu">
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenuClick}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={handleMenuClose}>Hot/New</MenuItem>
                            <MenuItem onClick={handleMenuCloseFac}>Favourites</MenuItem>
                            <MenuItem onClick={handleMenuClose}> <a href="https://www.instagram.com/vip.meapp/" target="_blank" rel="noopener noreferrer" style={{ color: "black", textDecoration: 'none' }}>Instagram</a></MenuItem>
                            <MenuItem onClick={handleMenuClose}> <a href="https://www.tiktok.com/@vip.meapp" target="_blank" rel="noopener noreferrer" style={{ color: "black", textDecoration: 'none' }}>Tik-Tok</a></MenuItem>
                            {isLoggedIn ? (
                                <MenuItem onClick={handleMenuCloses} >
                                    Log out
                                </MenuItem>
                            ) : (
                                <MenuItem onClick={handleMenuClose} >
                                    <button onClick={handleLoginButton} style={{ border: 'none', backgroundColor: 'transparent' }}>
                                        Log in
                                    </button>
                                </MenuItem>
                            )}
                        </Menu>
                    </div>

                </Toolbar>
            </div>

        </AppBar>
    );
}

export default Header;
