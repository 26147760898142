import React, { useState, useEffect } from "react";
import './Fav.css'
import VideoCard from "../VideoCard/VideoCard";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../Components/SearchInput/SearchInput";
import APIServices from "../../sevices/APIServices";
import { useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { setUserLat, setUserLong } from "../../Redux/Actions";
import { useDispatch } from "react-redux";

const Fav = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [favourite, setFav] = useState([]);
  const [loading, setLoading] = useState(false)
  const userDetails = useSelector((state) => {
    return state.userDetails;
  });

  useEffect(() => {

        fetchData(userDetails.mobile);
      
    
  }, []);


  const fetchData = async (mobile) => {
    setLoading(true);
    try {
      const response = await APIServices.favs({mobile:mobile});
      console.log(response)
      if (response.status !== 200) {
        throw new Error('Network response was not ok');
      }
      setLoading(false);
      setFav(response.data.data);

    } catch (error) {
      setLoading(false);
      console.log(error); // Optional: Set error state
    } finally {
      // setLoading(false); // Optional: End loading state
    }
  };


  const gotoEvent = (data) => {
    navigate(`/eventoverview/${data.eventId}`)

  }

  const convertDateFormat = (inputDate) => {
    // Split the input date into day, month, and year
    const [day, month, year] = inputDate.split('-');

    // Create a new Date object using the extracted values
    const date = new Date(`${year}-${month}-${day}`);

    // Get the day of the week (short form)
    const options = { weekday: 'short' };
    const dayOfWeek = new Intl.DateTimeFormat('en-US', options).format(date);

    // Get the month name (long form)
    const monthName = date.toLocaleString('default', { month: 'long' });

    // Format the final output as "Sat 27, July"
    return `${dayOfWeek} ${day}, ${monthName}`;
  };
  const filterImg = (data) => {
    // Filter URLs that match the image extensions
    const imageUrls = data.filter((url) => url.match(/\.(jpeg|jpg|gif|png|webp)$/));

    // Return the first image URL, or null if no image URLs are found
    return imageUrls.length > 0 ? imageUrls[0] : null;
  };

  return (
    <div className="home-bg-container ">
      <Backdrop
        sx={{
          color: "#ffffff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div style={{ marginTop: '30px' }}>


      </div>

      <div className="event-section">
        <h2>Favourites</h2>
        <div className="event-cards">
          {favourite.length === 0 ? (
            <div style={{ width: '100%', marginTop: '30px' }}>
              <h3 style={{ fontSize: '16px', textAlign: 'center' }}>There are no favourites. </h3>
            </div>
          ) : (
            favourite && favourite.map((event, index) => (
              <div key={index} className="event-card" onClick={() => gotoEvent(event)}>
                <img src={filterImg(event.allimages)} alt="Banner" className="banner-image" />
                <div className="event-name" >{event.eventName}</div>
                <div className="event-time" >{convertDateFormat(event.startDate)}</div>

                <div className="event-time"><span> <img src="/location.png" className="locations" /></span><span>{event.location}</span></div>
            
              </div>

              
            ))
            
          )}
        </div>

     
      </div>
    </div>
  );

}

export default Fav