export const actionTypes = {
  setAccessToken: "SET_ACCESS_TOKEN",
  setRefreshToken: "SET_REFRESH_TOKEN",
  setUserDetails: "SET_USER_DETAILS",
  setUserLat: "SET_USER_LAT",
  setUserLong: "SET_USER_LONG",
  setEventData: "SET_EVENT_DATA",
  setUserLocation: "SET_USER_LOCATION",
  setTicketDetails: "SET_TICKET_DETAILS",
  setAmountBody: "SET_AMOUNT_BODY"
};


export const setAccessToken = (data) => {
  return {
    type: actionTypes.setAccessToken,
    payload: data,
  };
};

export const setRefreshToken = (data) => {
  return {
    type: actionTypes.setRefreshToken,
    payload: data,
  };
};

export const setUserDetails = (data) => {
  return {
    type: actionTypes.setUserDetails,
    payload: data,
  };
};

export const setUserLat = (data) => {
  return {
    type: actionTypes.setUserLat,
    payload: data,
  };
};

export const setUserLong = (data) => {
  return {
    type: actionTypes.setUserLong,
    payload: data,
  };
};
export const setEventData = (data) => {
  return {
    type: actionTypes.setEventData,
    payload: data,
  };
};
export const setUserLocation = (data) => {
  return {
    type: actionTypes.setUserLocation,
    payload: data,
  };
};

export const setTicketDetails = (data) => {
  return {
    type: actionTypes.setTicketDetails,
    payload: data,
  };
};

export const setAmountBody = (data) => {
  return {
    type: actionTypes.setAmountBody,
    payload: data,
  };
};