// import React, { useState, useEffect } from "react";
// import { IconButton, InputAdornment, TextField } from "@mui/material";
// import { useNavigate, Link } from "react-router-dom";
// import { Visibility, VisibilityOff } from "@mui/icons-material";
// import ButtonCompo from "../../Components/ButtonComponent/ButtonComponent";
// import APIServices from "../../sevices/APIServices";
// import { Card, Avatar, Box, Dialog, Button, } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
// import Backdrop from "@mui/material/Backdrop";
// import CircularProgress from "@mui/material/CircularProgress";
// import './Signup.css'

// const Signup = () => {
//   const navigate = useNavigate();
//   const [showErrorPopup, setShowErrorPopup] = useState(false)
//   // const dispatch = useDispatch();

//   const [fullName, setFullName] = useState("");
//   const [email, SetEmail] = useState("");
//   const [mobileNumber, setMobileNumber] = useState("");
//   const [password, setPassword] = useState("");
//   const [conformPassword, setConformPassword] = useState("")
//   const [showPassword, setShowPassword] = useState(false);
//   const [showConformPassword, setShowConformPassword] = useState(false)
//   const [passwordError, setPasswordError] = useState("");
//   const [conformPasswordError, setConformPasswordError] = useState("")
//   const [nameError, setNameError] = useState(null);
//   const [isValid, setIsValid] = useState(true);
//   const [loading, setLoading] = useState(false)
//   const [invalidEmailAddressError, setInvalidEmailAddressError] =
//     useState(false);
//   const [alreadyUsedEmail, setAlreadyUsedEmail] = useState(false);

//   const generateRandomString = () => {
//     const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//     let result = '';

//     for (let i = 0; i < 14; i++) {
//       const randomIndex = Math.floor(Math.random() * characters.length);
//       result += characters.charAt(randomIndex);
//     }

//     return result;
//   }

//   const closeShareShaveEventPopup = () => {
//     setShowErrorPopup(false);
//   };

//   const handleSingupSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true)
//     if (mobileNumber !== "" && password !== "" && conformPassword !== "" && fullName !== "" && email !== "") {
//       const body = {
//         Fullname: fullName,
//         mobile: mobileNumber,
//         Email: email,
//         Role: 'User',
//         password: password,
//         uniqueDeviceId: generateRandomString(),
//         Adfree: true,
//         Message: 'congratulations your signup successful!!',
//       };
//       console.log(body)
//       try {
//         const response = await APIServices.signup(body);
//         console.log(response)
//         if (response.data.status === "failed") {
//           setLoading(false)
//           // console.log("Mobile or email doesn't exist");
//           setShowErrorPopup(true)
//           return;
//         } else if (response.data.status === "faileds") {
//           setLoading(false)
//           // setOpen(true);
//           console.log("server issues");
//           return;
//         } else {
//           setLoading(false)
//           if (response.data.status === 'success') {
//             navigate('/login')

//           }
//         }
//       }
//       catch (err) {
//         setLoading(false)
//         console.log("Getting error While Sign up: ", err)
//       } finally {
//         setLoading(false)
//       }
//     } else {
//       alert('enter all')
//       // setPasswordError(t('Login.enterPassword'));
//     }
//   };


//   const validateConformPassword = () => {
//     if (password !== conformPassword) {
//       setConformPasswordError("Passwords do not match")
//     }
//   }

//   const handleConformBlurPassword = () => {
//     validateConformPassword(password);
//   };
//   const handleChange = (e) => {
//     setPassword(e.target.value);
//     setPasswordError("");
//   };

//   const handleConformPassword = (e) => {
//     setConformPassword(e.target.value);
//     setPasswordError("");
//   };

//   const handleTogglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   const handleToggleShowPasswordVisibility = () => {
//     setShowConformPassword(!showConformPassword);
//   };

//   useEffect(() => {
//     if (typeof window !== "undefined") {
//       window.scrollTo({ top: 0, behavior: "smooth" });
//     }
//   }, []);


//   const handleMobileNumberChange = (e) => {
//     const inputMobileNumber = e.target.value;
//     setMobileNumber(inputMobileNumber);
//   };

//   const handleBackToLogin = () => {
//     setShowErrorPopup(false)
//     navigate("/login");
//   };

//   const refreshTokenExpirationDate = new Date();
//   refreshTokenExpirationDate.setDate(refreshTokenExpirationDate.getDate() + 30);

//   return (
//     <div className="signup-bg-container">
//       <Backdrop
//         sx={{
//           color: "#ffffff",
//           zIndex: (theme) => theme.zIndex.drawer + 1,
//         }}
//         open={loading}
//       >
//         <CircularProgress color="inherit" />
//       </Backdrop>
//       <div className="signup-form-container">
//         <div className="form-container-s">
//           <form className="form-s" onSubmit={handleSingupSubmit}>

//             <TextField
//               sx={{
//                 "& .MuiInputBase-input": {

//                   letterSpacing: "0.1px",
//                   fontSize: "20px",
//                   color: 'lightgray',
//                   padding: '10px 14px',
//                 },
//                 "& .MuiOutlinedInput-root": {
//                   "& fieldset": {
//                     borderColor: "lightgray",
//                   },
//                   "&:hover fieldset": {
//                     borderColor: "lightgray",
//                   },
//                   "&.Mui-focused fieldset": {
//                     borderColor: "lightgray",
//                   },
//                 },
//               }}
//               size="small"
//               type="text"
//               placeholder="Full name"
//               value={fullName}
//               onChange={(e) => {
//                 setFullName(e.target.value);
//                 setNameError(null);
//               }}
//               required
//             />

//             <TextField
//               size="small"
//               placeholder="Email"
//               type="text"
//               value={email}
//               sx={{
//                 "& .MuiInputBase-input": {

//                   letterSpacing: "0.1px",
//                   fontSize: "20px",
//                   color: 'lightgray',
//                   padding: '10px 14px',
//                 },
//                 "& .MuiOutlinedInput-root": {
//                   "& fieldset": {
//                     borderColor: "lightgray",
//                   },
//                   "&:hover fieldset": {
//                     borderColor: "lightgray",
//                   },
//                   "&.Mui-focused fieldset": {
//                     borderColor: "lightgray",
//                   },
//                 },
//               }}
//               onChange={(e) => {
//                 SetEmail(e.target.value);
//                 setNameError(null);
//               }}
//               required
//             />
//             {nameError ? (
//               <p
//                 className="password-condition-text"
//                 style={{ color: "red" }}
//               >
//                 {nameError}
//               </p>
//             ) : null}

//             <TextField
//               size="small"
//               sx={{
//                 "& .MuiInputBase-input": {

//                   letterSpacing: "0.1px",
//                   fontSize: "20px",
//                   color: 'lightgray',
//                   padding: '10px 14px',
//                 },
//                 "& .MuiOutlinedInput-root": {
//                   "& fieldset": {
//                     borderColor: "lightgray",
//                   },
//                   "&:hover fieldset": {
//                     borderColor: "lightgray",
//                   },
//                   "&.Mui-focused fieldset": {
//                     borderColor: "lightgray",
//                   },
//                 },
//               }}
//               placeholder="Mobile number"
//               type="tel"
//               value={mobileNumber}
//               onChange={handleMobileNumberChange}
//               required
//               error={!isValid}
//             />
//             <TextField
//               variant="outlined"
//               sx={{
//                 "& .MuiInputBase-input": {

//                   letterSpacing: "0.1px",
//                   fontSize: "20px",
//                   color: 'lightgray',
//                   padding: '10px 14px',
//                 },
//                 "& .MuiOutlinedInput-root": {
//                   "& fieldset": {
//                     borderColor: "lightgray",
//                   },
//                   "&:hover fieldset": {
//                     borderColor: "lightgray",
//                   },
//                   "&.Mui-focused fieldset": {
//                     borderColor: "lightgray",
//                   },
//                 },
//               }}
//               placeholder='Password'
//               size="small"
//               type={showPassword ? "text" : "password"}
//               value={password}
//               onChange={handleChange}
//               fullWidth
//               InputProps={{
//                 endAdornment: (
//                   <InputAdornment position="end">
//                     <IconButton
//                       onClick={handleTogglePasswordVisibility}
//                       edge="start"
//                       sx={{
//                         padding: 0,
//                         height: '100%',
//                         color: 'lightgray',
//                         marginLeft: '10px',
//                       }}
//                     >
//                       {showPassword ? (
//                         <VisibilityOff />
//                       ) : (
//                         <Visibility />
//                       )}
//                     </IconButton>
//                   </InputAdornment>
//                 ),
//               }}
//               error={Boolean(passwordError)}
//               helperText={passwordError}
//               InputLabelProps={{
//                 shrink: false,
//               }}
//             />


//             <TextField
//               variant="outlined"
//               sx={{
//                 "& .MuiInputBase-input": {

//                   letterSpacing: "0.1px",
//                   fontSize: "20px",
//                   color: 'lightgray',
//                   padding: '10px 14px',
//                 },
//                 "& .MuiOutlinedInput-root": {
//                   "& fieldset": {
//                     borderColor: "lightgray",
//                   },
//                   "&:hover fieldset": {
//                     borderColor: "lightgray",
//                   },
//                   "&.Mui-focused fieldset": {
//                     borderColor: "lightgray",
//                   },
//                 },
//               }}
//               placeholder='Confirm password'
//               size="small"
//               type={showConformPassword ? "text" : "password"}
//               value={conformPassword}
//               onChange={handleConformPassword}
//               onBlur={handleConformBlurPassword}
//               fullWidth
//               InputProps={{
//                 endAdornment: (
//                   <InputAdornment position="end">
//                     <IconButton
//                       onClick={handleToggleShowPasswordVisibility}
//                       edge="start"
//                       sx={{
//                         padding: 0,
//                         height: '100%',
//                         color: 'lightgray',
//                         marginLeft: '10px',
//                       }}
//                     >
//                       {showConformPassword ? (
//                         <VisibilityOff />
//                       ) : (
//                         <Visibility />
//                       )}
//                     </IconButton>
//                   </InputAdornment>
//                 ),
//               }}
//               error={Boolean(conformPasswordError)}
//               helperText={conformPasswordError}
//               InputLabelProps={{
//                 shrink: false,
//               }}
//             />

//             <center>
//               {" "}
//               <ButtonCompo
//                 btnText="Sign up"
//                 typeOfBtn="submit"
//                 disabledButton={
//                   email && fullName && mobileNumber && conformPassword && password && isValid ? false : true
//                 }
//               />{" "}
//             </center>
//           </form>
//           <center>
//             {" "}
//             <p className="sign-up-label-s">
//               Are you already a member? {" "}
//               <span className="sign-up-link" onClick={handleBackToLogin}>Login</span>{" "}
//             </p>
//           </center>
//         </div>
//       </div>
//       <Dialog
//         open={showErrorPopup}
//         aria-labelledby="report-dialog-title"
//         aria-describedby="report-dialog-description"
//       >
//         <Box className="report-comment-signup">
//           <div
//             style={{
//               alignSelf: "flex-end",
//               padding: "10px",
//             }}
//           >
//             <CloseIcon onClick={closeShareShaveEventPopup} />
//           </div>
//           <div style={{ display: 'flex', justifyContent: 'center', width: '100%', flexDirection: 'column', alignItems: 'center' }}>
//             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="100px" height="100px"><path fill="#f44336" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z" /><path fill="#fff" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z" /><path fill="#fff" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z" /></svg>

//             <h3 style={{ marginTop: '15px' }}>User already exist</h3>
//             <button className="login-button" onClick={handleBackToLogin}>Go to login</button>

//           </div>
//         </Box>
//       </Dialog>
//     </div>
//   )
// }

// export default Signup


import React, { useState, useEffect } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ButtonCompo from "../../Components/ButtonComponent/ButtonComponent";
import APIServices from "../../sevices/APIServices";
import { Dialog, Box, Backdrop, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import './Signup.css'

const Signup = () => {
  const navigate = useNavigate();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [conformPassword, setConformPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConformPassword, setShowConformPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const generateRandomString = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < 14; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  }

  const closeShareShaveEventPopup = () => {
    setShowErrorPopup(false);
  };

  const validateFields = () => {
    let newErrors = {};

    if (!fullName) newErrors.fullName = "Full name is required";
    if (!email) newErrors.email = "Email is required";
    if (!mobileNumber) newErrors.mobileNumber = "Mobile number is required";
    if (!password) newErrors.password = "Password is required";
    if (!conformPassword) newErrors.conformPassword = "Confirm password is required";
    if (password && conformPassword && password !== conformPassword) {
      newErrors.conformPassword = "Passwords do not match";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSingupSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!validateFields()) {
      setLoading(false);
      return;
    }

    const body = {
      Fullname: fullName,
      mobile: mobileNumber,
      Email: email,
      Role: 'User',
      password: password,
      uniqueDeviceId: generateRandomString(),
      Adfree: true,
      Message: 'Congratulations! Your signup was successful!!',
    };

    try {
      const response = await APIServices.signup(body);
      console.log(response);
      if (response.data.status === "failed") {
        setLoading(false);
        setShowErrorPopup(true);
        return;
      } else if (response.data.status === "faileds") {
        setLoading(false);
        console.log("Server issues");
        return;
      } else if (response.data.status === 'success') {
        navigate('/login');
      }
    } catch (err) {
      setLoading(false);
      console.log("Getting error while signing up: ", err);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (field, value) => {
    const setStateFunctions = {
      fullName: setFullName,
      email: setEmail,
      mobileNumber: setMobileNumber,
      password: setPassword,
      conformPassword: setConformPassword,
    };
    setStateFunctions[field](value);
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleShowPasswordVisibility = () => {
    setShowConformPassword(!showConformPassword);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  const handleBackToLogin = () => {
    setShowErrorPopup(false);
    navigate("/login");
  };

  return (
    
    <div className="signup-bg-container">
       <div className="logo">
                <img src="/loginlogo.png" className="logoImages" />
              </div>
      <Backdrop
        sx={{
          color: "#ffffff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="signup-form-container">
        <div className="form-container-s">
          <form className="form-s" onSubmit={handleSingupSubmit}>

            <TextField
              sx={{
                "& .MuiInputBase-input": {
                  letterSpacing: "0.1px",
                  fontSize: "20px",
                  color: 'lightgray',
                  padding: '10px 14px',
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "lightgray",
                  },
                  "&:hover fieldset": {
                    borderColor: "lightgray",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "lightgray",
                  },
                },
              }}
              size="small"
              type="text"
              placeholder="Full name"
              value={fullName}
              onChange={(e) => handleChange('fullName', e.target.value)}
              required
              error={Boolean(errors.fullName)}
              helperText={errors.fullName}
            />

            <TextField
              size="small"
              placeholder="Email"
              type="text"
              value={email}
              sx={{
                "& .MuiInputBase-input": {
                  letterSpacing: "0.1px",
                  fontSize: "20px",
                  color: 'lightgray',
                  padding: '10px 14px',
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "lightgray",
                  },
                  "&:hover fieldset": {
                    borderColor: "lightgray",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "lightgray",
                  },
                },
              }}
              onChange={(e) => handleChange('email', e.target.value)}
              required
              error={Boolean(errors.email)}
              helperText={errors.email}
            />

            <TextField
              size="small"
              sx={{
                "& .MuiInputBase-input": {
                  letterSpacing: "0.1px",
                  fontSize: "20px",
                  color: 'lightgray',
                  padding: '10px 14px',
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "lightgray",
                  },
                  "&:hover fieldset": {
                    borderColor: "lightgray",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "lightgray",
                  },
                },
              }}
              placeholder="Mobile number"
              type="tel"
              value={mobileNumber}
              onChange={(e) => handleChange('mobileNumber', e.target.value)}
              required
              error={Boolean(errors.mobileNumber)}
              helperText={errors.mobileNumber}
            />

            <TextField
              variant="outlined"
              sx={{
                "& .MuiInputBase-input": {
                  letterSpacing: "0.1px",
                  fontSize: "20px",
                  color: 'lightgray',
                  padding: '10px 14px',
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "lightgray",
                  },
                  "&:hover fieldset": {
                    borderColor: "lightgray",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "lightgray",
                  },
                },
              }}
              placeholder='Password'
              size="small"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => handleChange('password', e.target.value)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="start"
                      sx={{
                        padding: 0,
                        height: '100%',
                        color: 'lightgray',
                        marginLeft: '10px',
                      }}
                    >
                      {showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(errors.password)}
              helperText={errors.password}
              InputLabelProps={{
                shrink: false,
              }}
            />

            <TextField
              variant="outlined"
              sx={{
                "& .MuiInputBase-input": {
                  letterSpacing: "0.1px",
                  fontSize: "20px",
                  color: 'lightgray',
                  padding: '10px 14px',
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "lightgray",
                  },
                  "&:hover fieldset": {
                    borderColor: "lightgray",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "lightgray",
                  },
                },
              }}
              placeholder='Conform password'
              size="small"
              type={showConformPassword ? "text" : "password"}
              value={conformPassword}
              onChange={(e) => handleChange('conformPassword', e.target.value)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleToggleShowPasswordVisibility}
                      edge="start"
                      sx={{
                        padding: 0,
                        height: '100%',
                        color: 'lightgray',
                        marginLeft: '10px',
                      }}
                    >
                      {showConformPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(errors.conformPassword)}
              helperText={errors.conformPassword}
              InputLabelProps={{
                shrink: false,
              }}
            />

            <center>
              {" "}
              <ButtonCompo
                btnText="Sign up"
                typeOfBtn="submit"
              // disabledButton={
              //   email && fullName && mobileNumber && conformPassword && password && isValid ? false : true
              // }
              />{" "}
            </center>
          </form>
              <center>
            {" "}
             <p className="sign-up-label-s">
              Are you already a member? {" "}
               <span className="sign-up-link" onClick={handleBackToLogin}>Login</span>{" "}
             </p>
          </center>
        </div>
      </div>
      <Dialog
        open={showErrorPopup}
        aria-labelledby="report-dialog-title"
        aria-describedby="report-dialog-description"
      >
        <Box className="report-comment-signup">
          <div
            style={{
              alignSelf: "flex-end",
              padding: "10px",
            }}
          >
            <CloseIcon onClick={closeShareShaveEventPopup} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%', flexDirection: 'column', alignItems: 'center' }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="100px" height="100px"><path fill="#f44336" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z" /><path fill="#fff" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z" /><path fill="#fff" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z" /></svg>

            <h3 style={{ marginTop: '15px' }}>User already exist</h3>
            <button className="login-button" onClick={handleBackToLogin}>Go to login</button>

          </div>
        </Box>
      </Dialog>
    </div>
  );
};

export default Signup;
