import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React from 'react'

const AlertComponent = ({ open, severity, text, onClose }) => {
    return (
        <div>
            {<Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={onClose}
            >
                <Alert
                    onClose={onClose}
                    severity={severity}
                    variant="filled"
                    sx={{ width: '100%', }}
                >
                    {text}
                </Alert>
            </Snackbar>}
        </div>
    )
}

export default AlertComponent;
