import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Login from "./Pages/Login/Login";
import HomePage from "./Pages/Home/Home";
import ViewEvent from "./Pages/EventView/EventView";
import Signup from "./Pages/Signup/Signup";
// import PaymentStripe from "./Pages/paymentStripe/stripe";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Eventover from "./Pages/Eventoverview/Eventoverview";
import Checkout from "./Pages/checkout/checkout";
import StripSucccess from "./Pages/StripeSuccess/StripeSuccess";
import { useNavigate } from "react-router-dom";
import Eventsearch from "./Pages/Searchevent/searchevent";
import Fav from "./Pages/Favourite/Fav";
import APIServices from "./sevices/APIServices";
function App() {
 
  return (
    <Router>
      <Content />
    </Router>
  );
}


function Content() {
  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  useEffect(()=>{
    checkTokenExpiration();
    const fiveminutes = 5000;
    setInterval(() => {
      checkTokenExpiration();
    }, fiveminutes);
  },[])
  
  
  const checkTokenExpiration=() =>{
    const refreshtoken = localStorage.getItem('refreshtoken');
  
    if (refreshtoken || refreshtoken !== null) {
      const tokenPayload = JSON.parse(atob(refreshtoken.split('.')[1]));
  
      const expiration = tokenPayload.exp * 1000;
      const currentTime = new Date().getTime();
  
      if (currentTime > expiration) {
        localStorage.clear();
        navigate('/login')
      } else {
        const token = localStorage.getItem('jwttoken');
  
        if (token) {
          const tokenPayload = JSON.parse(atob(token.split('.')[1]));
  
          const expiration = tokenPayload.exp * 1000;
          const currentTime = new Date().getTime();
  
          if (currentTime > expiration) {
          generateRefreshToken();
          }
        }
      }
    } else {
    }
  }
  
  
  const generateRefreshToken = async (e) => {
    const refreshToken = localStorage.getItem('refreshtoken');
    const body = {
      refreshToken: refreshToken,
  
    }
    try {
      const response = await APIServices.refresh(body);
      console.log(response)
      localStorage.setItem('jwttoken', response.accessToken);
      window.location.reload();
    
    
    }
    catch (err) {
      console.log("Getting Error While Login: ", err)
  
    } 
  
  };


  return (
    <div className="header-and-content-container">
      {isHomePage && <Header />}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/viewevent" element={<ViewEvent />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/eventoverview/:_id" element={<Eventover />} />
        <Route path="/stripesuccess" element={<StripSucccess />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/Favourite" element={<Fav />} />
        <Route path="/eventsearch" element={<Eventsearch />} />
      </Routes>
      {isHomePage && <Footer className="footer" />}
    </div>
  );
}

export default App;
