import React from "react";
import Button from "@mui/material/Button";
import "./ButtonComponent.css";

const ButtonCompo = ({
    typeOfBtn, btnText, onClick, disabledButton

}) => {
    return disabledButton ? (<div className="btn-container" > <Button type={
        typeOfBtn
    }

        className="background-butn-disabled" disabled> {
            btnText
        }

        {
            " "
        }

    </Button> </div>) : (<div className="btn-container" > <Button type={
        typeOfBtn
    }

        className="background-butn" onClick={
            onClick
        }

    > {
            btnText
        }

        {
            " "
        }

    </Button> </div>);
}

export default ButtonCompo;