import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ButtonCompo from "../../Components/ButtonComponent/ButtonComponent";
import APIServices from "../../sevices/APIServices";
import "./Login.css";
import { useDispatch } from "react-redux";
import { setUserDetails, setAccessToken, setRefreshToken } from "../../Redux/Actions";
import AlertComponent from "../../Components/Alert/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [userphoneNumber, setUserPhoneNumber] = useState(null);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showRequest, setShowRequest] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [verifyOtp, setVerifyOtp] = useState(false)
  const [code, setCode] = useState("")
  const [resetPassword, setResetPassword] = useState("")
  const [conformResetPassword, setConformResetPassword] = useState("")
  const [resetPasswordDialouge, setResetPasswordDialouge] = useState(false)
  const [loginSuceess, setLoginSuccess] = useState(false)
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [loginError, setLoginError] = useState(false)
  const [validOtp, setInavlidOtp] = useState(false)
  const [restPasswordError, setResetPasswordError] = useState(false)

  const handleChange = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const refreshTokenExpirationDate = new Date();
  refreshTokenExpirationDate.setDate(refreshTokenExpirationDate.getDate() + 90);



  const handlePhoneNumberChange = (e) => {
    const phoneNumber = e.target.value;
    setPhoneNumber(phoneNumber);
  };

  const handleUserEmail = (e) => {
    const userMail = e.target.value;
    setUserPhoneNumber(userMail);
  };

  const handleSubmit = async (e) => {
    setShowLoader(true)
    e.preventDefault();
    if (phoneNumber !== "" && password !== "") {
      const body = {
        mobile: phoneNumber,
        password: password,

      }
      try {
        const response = await APIServices.login(body);
        console.log(response)
        if (response.data.status === "failed") {
          setLoginError(true)
          setShowLoader(false)
          console.log("Invalid phone number");
          return;
        } else if (response.data.status === "invalid") {
          setLoginError(true)
          setShowLoader(false)
          // setOpen(true);
          console.log("incorrect password");
          return;
        }
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("refreshToken", response.data.refreshToken);
        localStorage.setItem(
          "refreshTokenExpiraitonDate",
          JSON.stringify(refreshTokenExpirationDate)
        );
        dispatch(setUserDetails(response.data.user));
        dispatch(setAccessToken(response.data.token));
        dispatch(setRefreshToken(response.data.refreshToken));
        APIServices.setAuthToken(response.data.token);
        setShowLoader(false)
        if (response.data.user.Role === 'User') {
          navigate('/')
        } else {
          console.log('business')
        }
      }
      catch (err) {
        console.log("Getting Error While Login: ", err)
        setLoginError(true)
        setShowLoader(false)
      } finally {
        setShowLoader(false)
      }
    } else if (password === "") {
      setShowLoader(false)
      // setPasswordError(t('Login.enterPassword'));
    }
  };

  const handleSetForgotPassword = () => {
    setShowForgotPassword(!showForgotPassword);
    setShowRequest(false);
  };

  const handleShowRequestSent = (e) => {
    setShowLoader(true)
    e.preventDefault();
    const otp = Math.floor(100000 + Math.random() * 900000);
    const body = {
      Email: userphoneNumber,
      otp: otp
    }

    APIServices.forgotPassword(body)
      .then((response) => {
        if (response.data.status === 'otpsent') {
          setVerifyOtp(!verifyOtp)
          setShowForgotPassword(false)
          localStorage.setItem('userDocId', response.data.Authentication)
          localStorage.setItem('otp', response.data.otp);
          setShowLoader(false)
          return;
        }
        if (response.data.status === 'failed') {
          console.log('The provided email does not exist,please proceed to register')
          setShowLoader(false)
          return;
        }
      })
      .catch((err) => {
        setForgotPasswordEmail(true)
        console.log("Error fetching forgot password: ", err);
        setShowLoader(false)

      });
  };

  const handleRegister = () => {
    navigate("/signup");
  };

  const handleVerifyOtp = (e) => {
    e.preventDefault();
    const otp = localStorage.getItem('otp')
    if (code.length === 6) {
      if (otp === code) {
        setShowForgotPassword(false)
        setVerifyOtp(false)
        setResetPasswordDialouge(true)
        localStorage.removeItem('otp')
      } else {
        setInavlidOtp(true)
      }
    } else {
      setInavlidOtp(true)
    }

  }

  const handleConformResetPassword = () => {
    setShowLoader(true)
    const userDocId = localStorage.getItem('userDocId');
    if (resetPassword === conformResetPassword) {
      const body = { password: resetPassword, userDocId: userDocId }
      APIServices.updatePassword(body)
        .then((response) => {
          setShowForgotPassword(false)
          setVerifyOtp(false)
          setResetPasswordDialouge(false)
          localStorage.removeItem('userDocId')
          setShowLoader(false)
        })
        .catch((err) => {
          console.log("Error fetching event details: ", err);
          setShowLoader(false)
          setResetPasswordError(true)
        });
    } else {
      setShowLoader(false)
      setResetPasswordError(true)
    }
  }

  return (
    <>
      <div className="bg-container">
        <Backdrop
          sx={{
            color: "#ffffff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={showLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="login-container">
          {showForgotPassword ? (
            <div className="form-container">
              <form className="form-forgot-password"
                onSubmit={handleSubmit}
              >
                <p className="recover-password-text">
                  Let's recover your password
                </p>
                {showRequest ? (
                  <>
                    <p className="request-sent-text-1">
                      We've received your password reset request.
                    </p>
                    <p className="request-sent-text-2">
                      Please check your email for instructions on how to reset your password.
                      If you don't see the mobile number right away, don't forget to check in your spam folder.
                    </p>
                    <p
                      className="return-to-login"
                      onClick={handleSetForgotPassword}
                    >
                      Return to Log In
                    </p>
                  </>
                ) : (
                  <>
                    <p className="forgot-password-text">
                      Forgot your password? Type in your mobile number address below to reset your password.
                    </p>
                    <TextField
                      size="small"
                      type="email"
                      sx={{
                        "& .MuiInputBase-input": {
                          fontFamily: "Poppins",
                          letterSpacing: "0.1px",
                          fontSize: "20px",
                          color: 'lightgray',
                          padding: '10px 14px',
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "lightgray",
                          },
                          "&:hover fieldset": {
                            borderColor: "lightgray",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "lightgray",
                          },
                        },
                      }}
                      placeholder="Email"
                      value={userphoneNumber}
                      error={!isValid}
                      helperText={!isValid ? 'Please enter a valid mobile number' : ""}
                      FormHelperTextProps={{
                        sx: { fontFamily: "Poppins" },
                      }}
                      onChange={handleUserEmail}
                      required
                      className="login-input-phone-number"
                      fullWidth
                    />
                    <p
                      className="return-to-login"
                      onClick={handleSetForgotPassword}
                    >
                      Return to Log In
                    </p>
                    <center>
                      <ButtonCompo
                        btnText="Sumbit"
                        onClick={handleShowRequestSent}
                        disabledButton={userphoneNumber ? false : true}
                      />{" "}
                    </center>
                  </>
                )}
              </form>
            </div>
          ) : verifyOtp ? (
            <div className="form-container">
              <form className="form-code">
                <p className="sign-up-label-code">
                  Please enter the verification code sent to your email address. Don’t forget to check your{" "}
                  <span style={{ fontWeight: "bold" }}>spam</span>{" "}
                  folder.
                </p>
                <input
                  type="text"
                  className="input-s"
                  placeholder="Enter your otp"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') { handleVerifyOtp(e) }
                  }}
                  required
                />
                <center>
                  <ButtonCompo
                    btnText=" Verify otp"
                    onClick={(e) => handleVerifyOtp(e)}
                  />{" "}
                </center>
              </form>
            </div>
          ) : resetPasswordDialouge ? (
            <div className="form-container">
              <form className="form-code">
                <p className="sign-up-label-code">
                  Please enter the password to reset
                </p>
                <input
                  type="text"
                  className="input-s"
                  placeholder="Password"
                  value={resetPassword}
                  onChange={(e) => setResetPassword(e.target.value)}
                  required
                />

                <input
                  type="text"
                  className="input-s"
                  placeholder="Conform password"
                  value={conformResetPassword}
                  onChange={(e) => setConformResetPassword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') { handleVerifyOtp(e) }
                  }}
                  required
                />

                <center>
                  <ButtonCompo
                    btnText="Conform"
                    onClick={(e) => handleConformResetPassword(e)}
                  />{" "}
                </center>
              </form>
            </div>
          ) : (
            <div>
              <div className="logo">
                <img src="/loginlogo.png" className="logoImages" />
              </div>


              <div className="form-container">

                <form className="form" onSubmit={handleSubmit}>
                  <h5 className="phone-heading">Phone number</h5>
                  <TextField
                    size="small"
                    sx={{
                      "& .MuiInputBase-input": {

                        letterSpacing: "0.1px",
                        fontSize: "20px",
                        color: 'lightgray',
                        padding: '10px 14px',
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "lightgray",
                        },
                        "&:hover fieldset": {
                          borderColor: "lightgray",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "lightgray",
                        },
                      },
                    }}
                    placeholder="Mobile Number"
                    type="tel"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    required
                    error={!isValid}
                    helperText={!isValid ? 'Please enter a valid mobile number' : ""}
                    FormHelperTextProps={{ sx: { fontFamily: "Poppins" } }}
                    className="login-input-phone-number"
                    fullWidth
                  />
                  <h5 className="phone-heading">Password</h5>

                  <TextField
                    variant="outlined"
                    sx={{
                      "& .MuiInputBase-input": {
                        letterSpacing: "0.1px",
                        fontSize: "20px",
                        color: 'lightgray',
                        padding: '10px 14px',
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "lightgray", // Make the border transparent
                        },
                        "&:hover fieldset": {
                          borderColor: "lightgray", // Keep it transparent on hover
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "lightgray", // Keep it transparent when focused
                        },
                      },
                    }}
                    placeholder="Password"
                    size="small"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={handleChange}
                    className="login-input-phone-number"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleTogglePasswordVisibility}
                            edge="end"
                            sx={{
                              padding: 0,
                              height: '90%',
                              color: 'lightgray',
                              margin: '0px',
                              marginRight: '10px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(passwordError)}
                    helperText={passwordError}
                    FormHelperTextProps={{ sx: { fontFamily: "Poppins" } }}
                    InputLabelProps={{
                      shrink: false,
                    }}
                  />


                  <p
                    className="forgot-text"
                    onClick={handleSetForgotPassword}
                  >
                    Forgot Password
                  </p>
                  <center>
                    <ButtonCompo
                      btnText="Login"
                      typeOfBtn="submit"
                      disabledButton={
                        phoneNumber && password && isValid ? false : true
                      }
                    />
                  </center>
                </form>
                <br></br>

                <center>
                  {" "}
                  <p className="sign-up-label">
                    Not a member yet?{" "}
                    <span className="sign-up-link" onClick={handleRegister}>
                      Sign up
                    </span>{" "}
                  </p>
                </center>
              </div>
            </div>
          )}
        </div>
        <AlertComponent
          open={loginSuceess}
          severity="success"
          onClose={() => setLoginSuccess(false)}
          text="Login sucessfully"
        />
        <AlertComponent
          open={forgotPasswordEmail}
          severity="error"
          onClose={() => setForgotPasswordEmail(false)}
          text="Please enter registered email"
        />
        <AlertComponent
          open={loginError}
          severity="error"
          onClose={() => setLoginError(false)}
          text="Inavlid phone number or password"
        />

        <AlertComponent
          open={validOtp}
          severity="error"
          onClose={() => setInavlidOtp(false)}
          text="Inavlid otp"
        />

        <AlertComponent
          open={restPasswordError}
          severity="error"
          onClose={() => setResetPasswordError(false)}
          text="Password do not match"
        />

      </div>
    </>
  );
}

export default Login;