import React, { useEffect, useState } from 'react';
import './ReviewSection.css';
import APIServices from "../../sevices/APIServices";
// const reviews = [
//   {
//     date: 'Jan 20, 2024',
//     rating: 5,
//     name: 'Alex K.',
//     position: 'Senior Analyst',
//     review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
//   },
//   {
//     date: 'Nov 13, 2023',
//     rating: 4,
//     name: 'Emily R.',
//     position: 'Front-End Engineer',
//     review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
//   },
//   // Add more reviews as needed
// ];



const ReviewSection = () => {
  const [eventData, setEventData] = useState(null);
  const [eventReviews, setReviews] = useState([]);

  const [averageRating, setAverageRating] = useState(0);
  const [ratingDistribution, setRatingDistribution] = useState([0, 0, 0, 0, 0]);
  useEffect(() => {
    const event = localStorage.getItem('event');
    if (event) {
      const parsedEvent = JSON.parse(event);
      console.log(parsedEvent)
      setEventData(parsedEvent);

    }
  }, []);

  useEffect(() => {
    if (eventData) {
      const { eventId } = eventData;
      if (eventId) {
        getReviews(eventId)
      }
    }
  }, [eventData]);
  const getReviews = async (eventid) => {
    console.log(eventid)
    try {
      const response = await APIServices.getEventReviews(eventid);


      setReviews(response.data.data)



    }
    catch (err) {
      console.log(err)
    }

  };



  useEffect(() => {
    if (eventReviews.length > 0) {
      // Calculate the average rating
      const totalRating = eventReviews.reduce((sum, review) => sum + parseFloat(review.rating), 0);
      const average = (totalRating / eventReviews.length).toFixed(1);
      setAverageRating(average);
      const distribution = [0, 0, 0, 0, 0];
      eventReviews.forEach(review => {
        const ratingIndex = 5 - parseInt(review.rating);
        distribution[ratingIndex] += 1;
      });
      setRatingDistribution(distribution);
    }
  }, [eventReviews]);


  return (
    <div className="review-section">
      <div className="average-rating">
        <h3>Event Reviews</h3>
        <div className="rating-summary">
          <div className="average-score">
            <h1>{averageRating || 0}</h1>
            <div className="stars" >
              {[...Array(Number(5))].map((_, index) => (
                <span key={index} className="star">&#9733;</span>
              ))}
            </div>
            <p>({eventReviews.length || 0} Reviews)</p>
          </div>
          <div className="rating-bars">
            {[5, 4, 3, 2, 1].map((star, index) => (
              <div key={index} className="rating-bar">
                <span>{star} stars</span>
                <div className="bar">
                  <div className="fill" style={{ width: `${ratingDistribution[5 - star]}px` }}></div>
                </div>
                <span>{ratingDistribution[5 - star]}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      {eventData ? (
        <div className="reviews-list">
          {eventReviews && eventReviews.map((review, index) => (
            <div key={index} className="review-card">
              <p className="review-date">{review.postedDate}</p>
              <div className="review-rating">
                {[...Array(Number(review.rating))].map((_, index) => (
                  <span key={index} className="star">&#9733;</span>
                ))}
              </div>
              <div className="reviewer-info">
                <div className="reviewer-initials"><img src={review.profileImage} className='img-tag' /></div>
                <div className="reviewer-details">
                  <h4>{review.userName}</h4>
                </div>
              </div>
              <p className="review-text">{review.description}</p>
            </div>
          ))}
        </div>
      ) : (
        <p>No ratings yet</p>
      )}
    </div>

  );
};

export default ReviewSection;
