import React, { useEffect, useState, useRef, } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "./Eventoverview.css";
import ReviewSection from "../ReviewSection/ReviewSection";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";
import APIServices from "../../sevices/APIServices";
import { useParams } from "react-router-dom";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Card, Avatar, Box, Dialog, Button, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AlertComponent from "../../Components/Alert/Alert";
// import {Map,Marker, GoogleApiWrapper} from 'google-maps-react';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import { setEventData, setTicketDetails } from "../../Redux/Actions";

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  GabIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
  XIcon,
} from "react-share";

import {
  EmailShareButton,
  FacebookShareButton,
  GabShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const Eventover = (props) => {
  const params = useParams();
  const eventId = params._id;
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("concert");
  const [bookingType, setBookingType] = useState("me");
  const [phoneNumber, setPhoneNumber] = useState('');
  const [imageUrls, setImageUrls] = useState([]);
  const [Archives, setArchives] = useState("");
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const [opensharePop, setOpenSharePop] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [shareEventPopup, setShareEventPopup] = useState(false);
  const [copiedText, setCopiedText] = useState(false)
  const [showMap, setShowMap] = useState(false);
  const [isFavorited, setIsFavorited] = useState(false);



  const eventData = useSelector((state) => {
    return state.eventData;
  });


  const dispatch = useDispatch()
  const toggleMap = () => {
    // setShowMap(!showMap);


    const latitude = eventData.locationCoordinates.coordinates[1]
    const longitude = eventData.locationCoordinates.coordinates[0]
    const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(url, "_blank");


  };
  const eventUrl = `https://vipme-event-website.vercel.app/eventoverview/${eventId}`;

  const userDetails = useSelector((state) => {
    return state.userDetails;
  });
  useEffect(() => {
    setShowLoader(true);
    APIServices.getEvent(eventId)
      .then((response) => {
        setShowLoader(false);
        // setEventData(response.data.data);
        console.log(response.data.data);
        dispatch(setEventData(response.data.data));
        setArchives(response.data.data.allimages)
        if (response.data.data) {
          const filteredImages = response.data.data.allimages.filter((url) => {
            console.log("url: ", url);
            return url.match(/\.(jpeg|jpg|gif|png|webp)$/);
          });
          setImageUrls(filteredImages);
        }
      })
      .catch((err) => {
        console.log("Error fetching event details: ", err);
        setShowLoader(false);
      });

    console.log(imageUrls);
  }, []);

  useEffect(() => {
    const checkFavoriteStatus = async () => {
      if (userDetails && eventData) {
        const body = {
          mobile: userDetails.mobile,
          eventId: eventData._id, // Ensure you use `eventData._id` or the correct identifier
        };
        console.log('Request body:', body);

        try {
          const response = await APIServices.checkFavuorite(body); // Ensure `checkFavuorite` is an async function

          if (response.data.status === "true") {
            setIsFavorited(!isFavorited); // Set based on the response status
          } else if (response.data.status === "false") {
            setIsFavorited(false); // Set based on the response status
          } else {
            console.log("Unexpected status:", response.data.status);
          }
        } catch (err) {
          console.error("Error while checking favorite status:", err);
        }
      }
    };

    checkFavoriteStatus(); // Call the async function inside useEffect

  }, [userDetails, eventId]);
  const [forms, setForms] = useState([
    { id: 1, fullName: "", email: "", phoneNumber: "" },
    { id: 2, fullName: "", email: "", phoneNumber: "" },
  ]);

  const [errors, setErrors] = useState({});

  const handleBookingChange = (event) => {
    setBookingType(event.target.value);
    if (event.target.value !== "others") {
      setForms([
        { id: 1, fullName: "", email: "", phoneNumber: "" },
        { id: 2, fullName: "", email: "", phoneNumber: "" },
      ]);
      setErrors({});
    }
  };

  // Handle input change
  const handleInputChange = (id, field, value) => {
    setForms(
      forms.map((form) => (form.id === id ? { ...form, [field]: value } : form))
    );
  };
  const toggleFavorite = () => {
    if (isLoggedIn) {
      if (isFavorited) {
        setIsFavorited(!isFavorited);
        removeFavuorite()
      } else {
        setIsFavorited(!isFavorited);
        addFavuorite()
      }
    } else {
      alert("Please login to add into favourite items")
    }
  };


  // Handle add form
  const handleAddForm = () => {
    setForms([
      ...forms,
      {
        id: Date.now(),
        fullName: "",
        email: "",
        phoneNumber: "",
        postalCode: "",
      },
    ]);
  };

  // Handle delete form
  const handleDeleteForm = (id) => {
    setForms(forms.filter((form) => form.id !== id));
  };

  const handlePhoneNumberChange = (e) => {
    const phoneNumber = e.target.value;
    setPhoneNumber(phoneNumber);
  };

  const handleUserEmail = (e) => {
    const userMail = e.target.value;
    setEmail(userMail);
  };

  const handleuserName = (e) => {
    const userName = e.target.value;
    setUserName(userName);
  };

  // Validate forms
  const validateForms = () => {
    const newErrors = {};
    forms.forEach((form, index) => {
      if (!form.fullName)
        newErrors[`fullName_${form.id}`] = `Guest ${index + 1
          } - Full Name is required.`;
      if (!form.email)
        newErrors[`email_${form.id}`] = `Guest ${index + 1
          } - Email is required.`;
      if (!form.phoneNumber)
        newErrors[`phoneNumber_${form.id}`] = `Guest ${index + 1
          } - Phone Number is required.`;
      // if (!form.postalCode) newErrors[`postalCode_${form.id}`] = `Guest ${index + 1} - Postal Code is required.`;
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForms()) {
      // Submit forms data
      console.log(forms);
    }
  };
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRefs = useRef([]);

  const handlePlayPause = (index) => {
    const videoPlayer = videoRefs.current[index];

    if (currentPlayingIndex === index) {
      if (isPlaying) {
        videoPlayer.getInternalPlayer().pause();
        setIsPlaying(false);
      } else {
        videoPlayer.getInternalPlayer().play();
        setIsPlaying(true);
      }
    } else {
      if (currentPlayingIndex !== null) {
        const prevPlayer = videoRefs.current[currentPlayingIndex];
        if (prevPlayer) {
          prevPlayer.getInternalPlayer().pause();
        }
      }
      videoPlayer.getInternalPlayer().play();
      setIsPlaying(true);
      setCurrentPlayingIndex(index);
    }
  };

  const renderMedia = (url, index) => {
    const isVideo = url.endsWith(".mp4") || url.endsWith(".mov"); // Add other video formats if needed

    return isVideo ? (
      <div className="video-container">
        <ReactPlayer
          url={url}
          playing={currentPlayingIndex === index && isPlaying}
          loop
          autoPlay
          muted
          width="100%"
          height="100%"
          ref={(el) => (videoRefs.current[index] = el)}
          className="video-player"
        />
        <div
          className="play-button-overlay"
          onClick={() => handlePlayPause(index)}
        >
          {currentPlayingIndex === index && isPlaying ? '⏸️' : '▶️'}
        </div>
      </div>
    ) : (
      <img src={url} alt="Event" className="archive-image" />
    );
  };
  const generateUniqueNumber = () => {
    return Math.floor(Math.random() * 90000) + 10000;
  };

  const buyTickets = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";

    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    const ticketrandom = result;

    const uniqueId = generateUniqueNumber();

    const formData = [
      {
        customerName: userDetails?.Fullname,
        customerProfile: userDetails?.profileImage,
        customerMobile: userDetails?.mobile,
        customerEmail: userDetails?.Email,
        eventId: eventData?.eventId,
        eventName: eventData?.eventName,
        startDate: eventData?.startDate,
        guestName: userName,
        guestMobile: phoneNumber,
        guestEmail: email,
        startTime: eventData?.startTime,
        endTime: eventData?.endTime,
        endDate: eventData?.endDate,
        organizerName: eventData?.organizerName,
        price: eventData?.price,
        location: eventData?.location,
        organizerNumber: eventData?.organizerNumber,
        eventType: eventData?.eventType,
        noOfTickets: "1",
        bookingNo: eventData?.bookingNo,
        paymentDone: "false",
        amountPaid: "0",
        ratingGiven: "0",
        Flag: "Checkout",
        eventImage: imageUrls[0],
        uniqueId: uniqueId.toString(),
        ticketuniqueId: ticketrandom,
        qrCodeURL: "",
        _id: "",
      },
    ];
    dispatch(setTicketDetails(formData))
    // localStorage.setItem("ticketDetails", JSON.stringify(formData))
    if (isLoggedIn) {
      const currentDate = new Date();
      const day = currentDate.getDate();
      const month = currentDate.getMonth() + 1;
      const year = currentDate.getFullYear();
      const hours = currentDate.getHours();
      const minutes = currentDate.getMinutes();

      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;

      const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;
      const [currentDay, currentMonth, currentYear] = formattedDate
        .split("-")
        .map(Number);
      const currentFormattedDate = new Date(
        currentYear,
        currentMonth - 1,
        currentDay
      );

      const [eventDay, eventMonth, eventYear] = eventData.startDate
        .split("-")
        .map(Number);
      const eventFormattedDate = new Date(eventYear, eventMonth - 1, eventDay);

      if (currentFormattedDate > eventFormattedDate) {
        alert(
          "The ticket booking period has ended. We appreciate your interest in this event. Please stay tuned for future events and opportunities. Thank you for your understanding."
        );
      } else if (
        currentFormattedDate.getTime() === eventFormattedDate.getTime()
      ) {
        const eventStartTime = eventData.startTime.split(":").map(Number);
        const eventStartHour = eventStartTime[0];
        const eventStartMinute = eventStartTime[1];

        if (
          hours > eventStartHour ||
          (hours === eventStartHour && minutes > eventStartMinute)
        ) {
          alert(
            "The ticket booking period has ended. We appreciate your interest in this event. Please stay tuned for future events and opportunities. Thank you for your understanding."
          );
        } else {
          if (
            eventData.eventToggle === "false" ||
            eventData.eventToggle === false
          ) {
            alert("All tickets are sold out for this event.");
            return;
          }
          navigate("/checkout", {
            state: { from: "eventOverview", formData: formData },
          });
        }
      } else {
        if (
          eventData.eventToggle === "false" ||
          eventData.eventToggle === false
        ) {
          alert("All tickets are sold out for this event.");
          return;
        }
        navigate("/checkout", {
          state: { from: "eventOverview", formData: formData },
        });
      }
    } else {
      alert("Please log in first before booking tickets.")
      navigate("/login");
    }
  };

  const buyTicketsForOthers = () => {
    if (validateForms()) {
      const ticketRandom = () => {
        const characters =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";

        for (let i = 0; i < 10; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          result += characters.charAt(randomIndex);
        }
        return result;
      };

      const uniqueId = generateUniqueNumber();

      const formData = forms.map((form) => {
        const newObject = {
          customerName: userDetails?.Fullname,
          customerProfile: userDetails?.profileImage,
          customerMobile: userDetails?.mobile,
          customerEmail: userDetails?.Email,
          eventId: eventData?.eventId,
          eventName: eventData?.eventName,
          startDate: eventData?.startDate,
          guestName: form?.fullName,
          guestMobile: form?.phoneNumber,
          guestEmail: form?.email,
          startTime: eventData?.startTime,
          endTime: eventData?.endTime,
          endDate: eventData?.endDate,
          organizerName: eventData?.organizerName,
          price: eventData?.price,
          location: eventData?.location,
          organizerNumber: eventData?.organizerNumber,
          eventType: eventData?.eventType,
          noOfTickets: "1",
          bookingNo: eventData?.bookingNo,
          uniqueId: uniqueId.toString(),
          paymentDone: "false",
          amountPaid: "0",
          ratingGiven: "0",
          Flag: "Checkout",
          eventImage: eventData?.allimages[0],
          checked: false,
          ticketuniqueId: ticketRandom(),
          qrCodeURL: "",
          _id: "",
        };
        return newObject;
      });
      dispatch(setTicketDetails(formData))
      // localStorage.setItem("ticketDetails", JSON.stringify(formData))

      if (isLoggedIn) {
        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = currentDate.getMonth() + 1;
        const year = currentDate.getFullYear();
        const hours = currentDate.getHours();
        const minutes = currentDate.getMinutes();

        const formattedDay = day < 10 ? "0" + day : day;
        const formattedMonth = month < 10 ? "0" + month : month;

        const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;
        const [currentDay, currentMonth, currentYear] = formattedDate
          .split("-")
          .map(Number);
        const currentFormattedDate = new Date(
          currentYear,
          currentMonth - 1,
          currentDay
        );

        const [eventDay, eventMonth, eventYear] = eventData.startDate
          .split("-")
          .map(Number);
        const eventFormattedDate = new Date(eventYear, eventMonth - 1, eventDay);

        if (currentFormattedDate > eventFormattedDate) {
          alert(
            "The ticket booking period has ended. We appreciate your interest in this event. Please stay tuned for future events and opportunities. Thank you for your understanding."
          );
        } else if (
          currentFormattedDate.getTime() === eventFormattedDate.getTime()
        ) {
          const eventStartTime = eventData.startTime.split(":").map(Number);
          const eventStartHour = eventStartTime[0];
          const eventStartMinute = eventStartTime[1];

          if (
            hours > eventStartHour ||
            (hours === eventStartHour && minutes > eventStartMinute)
          ) {
            alert(
              "The ticket booking period has ended. We appreciate your interest in this event. Please stay tuned for future events and opportunities. Thank you for your understanding."
            );
          } else {
            if (
              eventData.eventToggle === "false" ||
              eventData.eventToggle === false
            ) {
              alert("All tickets are sold out for this event.");
              return;
            }
            const newqnty =
              Number(eventData?.eventticketQuantity) - Number(eventData?.bookedtickets);
            console.log(newqnty)
            if (newqnty >= Number(eventData?.ticketQuantity)) {
              navigate("/checkout", {
                state: { from: "eventOverview", formData: formData },
              });
            } else {
              alert("No tickets are available");
            }
          }
        } else {
          if (
            eventData.eventToggle === "false" ||
            eventData.eventToggle === false
          ) {
            alert("All tickets are sold out for this event.");
            return;
          }
          const newqnty =
            Number(eventData?.eventticketQuantity) - Number(eventData?.bookedtickets);
          console.log(newqnty)
          if (newqnty >= Number(eventData?.ticketQuantity)) {
            navigate("/checkout", {
              state: { from: "eventOverview", formData: formData },
            });
          } else {
            alert("No tickets are available");
          }
        }
      } else {
        alert("Please log in first before booking tickets.")
        navigate("/login");
      }

      console.log("Final formData array:", formData);
    }

  };

  const buyTicketsForSection = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";

    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    const ticketrandom = result;

    const uniqueId = generateUniqueNumber();

    const formData = [
      {
        customerName: userDetails?.Fullname,
        customerProfile: userDetails?.profileImage,
        customerMobile: userDetails?.mobile,
        customerEmail: userDetails?.Email,
        eventId: eventData?.eventId,
        eventName: eventData?.eventName,
        startDate: eventData?.startDate,
        guestName: userDetails?.Fullname,
        guestMobile: userDetails?.mobile,
        guestEmail: userDetails?.Email,
        bookSectionPrice: eventData?.bookSectionPrice,
        startTime: eventData?.startTime,
        endTime: eventData?.endTime,
        endDate: eventData?.endDate,
        organizerName: eventData?.organizerName,
        price: eventData?.price,
        location: eventData?.location,
        organizerNumber: eventData?.organizerNumber,
        eventType: eventData?.eventType,
        noOfTickets: '1',
        bookingNo: eventData?.bookingNo,
        paymentDone: 'false',
        amountPaid: "0",
        ratingGiven: "0",
        Flag: "Checkout",
        eventImage: imageUrls[0],
        uniqueId: uniqueId.toString(),
        ticketuniqueId: ticketrandom,
        qrCodeURL: "",
        _id: ""
      },
    ];
    dispatch(setTicketDetails(formData))
    // localStorage.setItem("ticketDetails", JSON.stringify(formData))
    if (isLoggedIn) {
      const currentDate = new Date();
      const day = currentDate.getDate();
      const month = currentDate.getMonth() + 1;
      const year = currentDate.getFullYear();
      const hours = currentDate.getHours();
      const minutes = currentDate.getMinutes();

      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;

      const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;
      const [currentDay, currentMonth, currentYear] = formattedDate
        .split("-")
        .map(Number);
      const currentFormattedDate = new Date(
        currentYear,
        currentMonth - 1,
        currentDay
      );

      const [eventDay, eventMonth, eventYear] = eventData.startDate
        .split("-")
        .map(Number);
      const eventFormattedDate = new Date(eventYear, eventMonth - 1, eventDay);

      if (currentFormattedDate > eventFormattedDate) {
        alert(
          "The ticket booking period has ended. We appreciate your interest in this event. Please stay tuned for future events and opportunities. Thank you for your understanding."
        );
      } else if (
        currentFormattedDate.getTime() === eventFormattedDate.getTime()
      ) {
        const eventStartTime = eventData.startTime.split(":").map(Number);
        const eventStartHour = eventStartTime[0];
        const eventStartMinute = eventStartTime[1];

        if (
          hours > eventStartHour ||
          (hours === eventStartHour && minutes > eventStartMinute)
        ) {
          alert(
            "The ticket booking period has ended. We appreciate your interest in this event. Please stay tuned for future events and opportunities. Thank you for your understanding."
          );
        } else {
          if (
            eventData.eventToggle === "false" ||
            eventData.eventToggle === false
          ) {
            alert("All tickets are sold out for this event.");
            return;
          }
          navigate("/checkout", {
            state: { from: "eventOverview", formData: formData },
          });
        }
      } else {
        if (
          eventData.eventToggle === "false" ||
          eventData.eventToggle === false
        ) {
          alert("All tickets are sold out for this event.");
          return;
        }
        navigate("/checkout", {
          state: { from: "eventOverview", formData: formData },
        });
      }
    } else {
      alert("Please log in first before booking tickets.")
      navigate("/login");
    }
  };

  const buyTicketsForSkipline = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";

    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    const ticketrandom = result;

    const uniqueId = generateUniqueNumber();

    const formData = [
      {
        customerName: userDetails?.Fullname,
        customerProfile: userDetails?.profileImage,
        customerMobile: userDetails?.mobile,
        customerEmail: userDetails?.Email,
        eventId: eventData?.eventId,
        eventName: eventData?.eventName,
        startDate: eventData?.startDate,
        guestName: eventData?.Fullname,
        guestMobile: userDetails?.mobile,
        guestEmail: userDetails?.Email,
        skipLinePrice: eventData?.skipLinePrice,
        startTime: eventData?.startTime,
        endTime: eventData?.endTime,
        endDate: eventData?.endDate,
        organizerName: eventData?.organizerName,
        price: eventData?.price,
        location: eventData?.location,
        organizerNumber: eventData?.organizerNumber,
        eventType: eventData?.eventType,
        noOfTickets: '1',
        bookingNo: eventData?.bookingNo,
        paymentDone: 'false',
        amountPaid: "0",
        ratingGiven: "0",
        Flag: "Checkout",
        eventImage: imageUrls[0],
        uniqueId: uniqueId.toString(),
        ticketuniqueId: ticketrandom,
        qrCodeURL: "",
        _id: ""
      },
    ];
    dispatch(setTicketDetails(formData))
    // localStorage.setItem("ticketDetails", JSON.stringify(formData))
    if (isLoggedIn) {
      const currentDate = new Date();
      const day = currentDate.getDate();
      const month = currentDate.getMonth() + 1;
      const year = currentDate.getFullYear();
      const hours = currentDate.getHours();
      const minutes = currentDate.getMinutes();

      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;

      const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;
      const [currentDay, currentMonth, currentYear] = formattedDate
        .split("-")
        .map(Number);
      const currentFormattedDate = new Date(
        currentYear,
        currentMonth - 1,
        currentDay
      );

      const [eventDay, eventMonth, eventYear] = eventData.startDate
        .split("-")
        .map(Number);
      const eventFormattedDate = new Date(eventYear, eventMonth - 1, eventDay);

      if (currentFormattedDate > eventFormattedDate) {
        alert(
          "The ticket booking period has ended. We appreciate your interest in this event. Please stay tuned for future events and opportunities. Thank you for your understanding."
        );
      } else if (
        currentFormattedDate.getTime() === eventFormattedDate.getTime()
      ) {
        const eventStartTime = eventData.startTime.split(":").map(Number);
        const eventStartHour = eventStartTime[0];
        const eventStartMinute = eventStartTime[1];

        if (
          hours > eventStartHour ||
          (hours === eventStartHour && minutes > eventStartMinute)
        ) {
          alert(
            "The ticket booking period has ended. We appreciate your interest in this event. Please stay tuned for future events and opportunities. Thank you for your understanding."
          );
        } else {
          if (
            eventData.eventToggle === "false" ||
            eventData.eventToggle === false
          ) {
            alert("All tickets are sold out for this event.");
            return;
          }
          navigate("/checkout", {
            state: { from: "eventOverview", formData: formData },
          });
        }
      } else {
        if (
          eventData.eventToggle === "false" ||
          eventData.eventToggle === false
        ) {
          alert("All tickets are sold out for this event.");
          return;
        }
        navigate("/checkout", {
          state: { from: "eventOverview", formData: formData },
        });
      }
    } else {
      alert("Please log in first before booking tickets.")
      navigate("/login");
    }
  };

  const handleShareEventClicked = () => {
    setOpenSharePop(!opensharePop);
  };

  const shareEventClicked = () => {
    setShareEventPopup(true);
  };
  const closeShareShaveEventPopup = () => {
    setShareEventPopup(false);
  };

  const handleCopiedEventLink = () => {
    setCopiedText(true)
  }

  const addFavuorite = async (e) => {
    const body = {
      mobile: userDetails.mobile,
      eventId: eventData._id,
    }
    try {
      const response = await APIServices.addFavuorite(body);
      console.log(response)
      if (response.data.status === "success") {
        console.log("added to favourite");
        return;
      } else if (response.data.status === "error") {
        console.log("failed");
        return;
      }


    }
    catch (err) {
      console.log("Getting Error While adding ", err)

    } finally {
    }
  };


  const removeFavuorite = async (e) => {
    const body = {
      mobile: userDetails.mobile,
      Id: eventData._id,

    }
    try {
      const response = await APIServices.removeFavuorite(body);
      console.log(response)
      if (response.data.status === "true") {
        console.log("removed favourite");
        return;
      } else if (response.data.status === "error") {
        console.log("failed to delete");
        return;
      }


    }
    catch (err) {
      console.log("Getting Error While Login: ", err)

    } finally {
    }
  };
  return (
    <div className="event-page">
      <Backdrop
        sx={{
          color: "#ffffff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={showLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <AlertComponent
        open={copiedText}
        severity="success"
        onClose={() => setCopiedText(false)}
        text="Link copied successfully"
      />
      <Dialog
        open={shareEventPopup}
        aria-labelledby="report-dialog-title"
        aria-describedby="report-dialog-description"
      >
        <Box className="report-comment-container">
          <div
            style={{
              alignSelf: "flex-end",
              padding: "10px",
            }}
          >
            <CloseIcon onClick={closeShareShaveEventPopup} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
            <WhatsappShareButton url={eventUrl}>
              <WhatsappIcon size={42} round={true} />
            </WhatsappShareButton>

            <TwitterShareButton url={eventUrl}>
              <TwitterIcon size={42} round={true} />
            </TwitterShareButton>

            <FacebookShareButton url={eventUrl}>
              <FacebookIcon size={42} round={true} />
            </FacebookShareButton>

            < InstapaperShareButton url={eventUrl}>
              <InstapaperIcon size={42} round={true} />
            </InstapaperShareButton>
          </div>
        </Box>
      </Dialog>
      {eventData ? (
        <>
          <div className="event-hero">
            {/* <div className="image-container">
              <img
                src={imageUrls[0]}
                alt="Party in the Park"
                className="event-image"
              />
              <div className="morevert-icon">
                <MoreVertIcon
                  className="icon-button"
                  onClick={handleShareEventClicked}
                />
                {opensharePop ? (
                  <div className="share-event-container">
                    <p onClick={shareEventClicked}>
                      Share Event
                    </p>
                    <div className="share-event-text"></div>
                    <CopyToClipboard text={eventUrl} >
                      <p onClick={handleCopiedEventLink}>Copy Event Link</p>
                    </CopyToClipboard>
                  </div>
                ) : null}
              </div>
            </div> */}
            <div className="image-container">
              <img
                src={imageUrls[0]}
                alt="Party in the Park"
                className="event-image"
              />
              <div className="morevert-icon">
                <MoreVertIcon
                  className="icon-button"
                  onClick={handleShareEventClicked}
                />
                {opensharePop ? (
                  <div className="share-event-container">
                    <p onClick={shareEventClicked}>Share Event</p>
                    <div className="share-event-text"></div>
                    <CopyToClipboard text={eventUrl}>
                      <p onClick={handleCopiedEventLink}>Copy Event Link</p>
                    </CopyToClipboard>
                  </div>
                ) : null}
              </div>
              {/* {!userDetails?.mobile ? null : (

              )} */}
              <div className="favorite-icon" onClick={toggleFavorite}>
                {isFavorited ? <Favorite /> : <FavoriteBorder />}
              </div>
            </div>
            <div className="event-info">
              <h1>{eventData.eventName}</h1>
              <p>{eventData.location}</p>
            </div>
          </div>

          <div className="event-tabs">
            <button
              className={activeTab === "concert" ? "active" : ""}
              onClick={() => setActiveTab("concert")}
            >
              Concert
            </button>
            <button
              className={activeTab === "reviews" ? "active" : ""}
              onClick={() => setActiveTab("reviews")}
            >
              Reviews
            </button>
          </div>
          <div className="event-description">
            {activeTab === "concert" ? (
              <div className="event-overview-container">
                <p className="event-descripition">{eventData.Description}</p>
                <div className="ticket-info">
                  <h3 className="ticket-price">Price: ${eventData.price}</h3>
                  <button className="ticket-btn">
                    Available Tickets{" "}
                    {Number(eventData.eventticketQuantity) -
                      Number(eventData.bookedtickets) || 0}
                  </button>
                </div>
                <div className="refund">
                  <h3>Location</h3>
                  <div>{eventData.address}</div>
                  <div>{eventData.location}</div>
                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                    <span style={{ fontWeight: '600' }}>For vendors or event questions</span>
                    <span> <span style={{ fontSize: '20px' }}>👉</span>&nbsp;{eventData.organizerName}&nbsp;{eventData.loginNumber}</span>
                  </div>
                  <br></br>
                  <p onClick={toggleMap} style={{ cursor: 'pointer', color: 'blue' }}>
                    Show map 📍
                  </p>

                  {/* {showMap && (
        <Map
          google={props.google}
          zoom={14}
          style={{ width: '90%', height: '50%' }}
          initialCenter={{
            lat: eventData.locationCoordinates.coordinates[1],
            lng: eventData.locationCoordinates.coordinates[0],
          }}
          onClick={props.onMapClicked}
        >
          <Marker
            onClick={props.onMarkerClick}
            name={'Current location'}
          />
        </Map>
      )} */}
                </div>
                <div className="refund">
                  <h3>Refund policy</h3>
                  <p>No Refunds</p>
                </div>

                <h3 className="complete-your-tickets-heading">
                  Complete Ticket Details
                </h3>

                {bookingType !== "others" ? (
                  <div className="forms-container">
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Full Name"
                        value={userName}
                        onChange={handleuserName}
                        className="event-overview-input"
                      />
                      <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={handleUserEmail}
                        className="event-overview-input"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Phone Number"
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        className="event-overview-input"
                      />
                      &nbsp;
                    </div>
                  </div>
                ) : (
                  <div className="forms-container">
                    {forms.map((form, index) => (
                      <div key={form.id} className="form-entry">
                        {index >= 2 && (
                          <button
                            type="button"
                            className="delete-form"
                            onClick={() => handleDeleteForm(form.id)}
                          >
                            Delete
                          </button>
                        )}
                        <h4>{`Guest ${index + 1}`}</h4>
                        <div className="form-group">
                          <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                            <input
                              type="text"
                              placeholder="Full Name"
                              value={form.fullName}
                              onChange={(e) =>
                                handleInputChange(
                                  form.id,
                                  "fullName",
                                  e.target.value
                                )
                              }
                              required
                              className="event-overview-input"
                            />
                            {errors[`fullName_${form.id}`] && (
                              <div className="error-message">
                                {errors[`fullName_${form.id}`]}
                              </div>
                            )}
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                            <input
                              type="email"
                              placeholder="Email"
                              value={form.email}
                              onChange={(e) =>
                                handleInputChange(
                                  form.id,
                                  "email",
                                  e.target.value
                                )
                              }
                              required
                              className="event-overview-input"
                            />
                            {errors[`email_${form.id}`] && (
                              <div className="error-message">
                                {errors[`email_${form.id}`]}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-group">
                          <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                            <input
                              type="text"
                              placeholder="Phone Number"
                              value={form.phoneNumber}
                              onChange={(e) =>
                                handleInputChange(
                                  form.id,
                                  "phoneNumber",
                                  e.target.value
                                )
                              }
                              required
                              className="event-overview-input"
                            />
                            {errors[`phoneNumber_${form.id}`] && (
                              <div className="error-message">
                                {errors[`phoneNumber_${form.id}`]}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="add-form"
                      onClick={handleAddForm}
                    >
                      Add More
                    </button>
                  </div>
                )}

                <form className="ticket-form" onSubmit={handleSubmit}>
                  <div className="radio-list-nav">
                    <h4 className="complete-your-tickets-heading">
                      Payment Methods
                    </h4>
                    <div className="radio-buttons">
                      <label>
                        <img
                          src="https://logowik.com/content/uploads/images/visa-new-20215093.jpg"
                          alt="Visa"
                        />
                      </label>
                      <label>
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGZVxQZ2UYa4aZMO1u_hgQPt-OVvqLq5MnoA&s"
                          alt="Mastercard"
                        />
                      </label>
                      <label>
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/MasterCard_Logo.svg/1280px-MasterCard_Logo.svg.png"
                          alt="Amex"
                        />
                      </label>
                      <label>
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTz9ldqSFmVU5Ka5IFiz7GaBYDLnJnN7rh-zg&s"
                          alt="Discover"
                        />
                      </label>
                    </div>
                  </div>

                  <div className="booking-options">
                    <h4>Booking Options</h4>
                    <label>
                      <input
                        type="radio"
                        name="booking"
                        value="me"
                        onChange={handleBookingChange}
                        checked={bookingType === "me"}
                      />{" "}
                      Book for me
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="booking"
                        value="others"
                        onChange={handleBookingChange}
                        checked={bookingType === "others"}
                      />{" "}
                      Book for others
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="booking"
                        value="section"
                        onChange={handleBookingChange}
                        checked={bookingType === "section"}
                      />{" "}
                      Book Section
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="booking"
                        value="skipLine"
                        onChange={handleBookingChange}
                        checked={bookingType === "skipLine"}
                      />{" "}
                      Skip line
                    </label>
                  </div>

                  <div className="links">
                    <a href="#instagram">Instagram</a>
                    <a href="#phone">Phone</a>
                    <a href="#website">Website</a>
                  </div>

                  {bookingType === "section" ? (phoneNumber === '' || email === '' || userName === '' ? (
                    <button
                      type="submit"
                      className="buy-tickets"
                      style={{ backgroundColor: 'lightgray' }}
                    >
                      Buy Section Tickets
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="buy-tickets"
                      onClick={buyTicketsForSection}
                    >
                      Buy Section Tickets
                    </button>
                  )
                  ) : bookingType === "others" ? (
                    <button
                      type="submit"
                      className="buy-tickets"
                      onClick={buyTicketsForOthers}
                    >
                      Buy Tickets For Others
                    </button>
                  ) : bookingType === "skipLine" ? (phoneNumber === '' || email === '' || userName === '' ? (
                    <button
                      type="submit"
                      className="buy-tickets"
                      style={{ backgroundColor: 'lightgray' }}
                    >
                      Buy Skipline Tickets
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="buy-tickets"
                      onClick={buyTicketsForSkipline}
                    >
                      Buy Skipline Tickets
                    </button>
                  )
                  ) : (phoneNumber === '' || email === '' || userName === '' ? (
                    <button
                      type="submit"
                      className="buy-tickets"
                      style={{ backgroundColor: 'lightgray' }}
                    >
                      Buy Tickets
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="buy-tickets"
                      onClick={buyTickets}
                    >
                      Buy Tickets
                    </button>
                  ))
                  }

                </form>

                <div className="archives">
                  <h3 className="complete-your-tickets-heading">Archives</h3>
                  {Archives &&
                    Archives.map((url, index) => (
                      <div className="archive-item" key={index}>
                        {renderMedia(url, index)}
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              <div>
                <ReviewSection />
              </div>
            )}
          </div>
        </>
      ) : (
        <div style={{ height: '100vh' }}>
          <h3 className="no-event-found">No event data found</h3>
        </div>
      )}
    </div>
  );
};
export default Eventover;

// export default GoogleApiWrapper({
//   apiKey: 'AIzaSyCcVoqALz3_DlftC6TiZxw6qJwdknDsYAc'
// })(Eventover);

