
import React from 'react';
// import FacebookSharpIcon from '@mui/icons-material/FacebookSharp';
// import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
// import XIcon from '@mui/icons-material/X';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import PinterestIcon from '@mui/icons-material/Pinterest';

import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-links">
        <p>  <a href="https://vipme-app.com/legal-terms/" target="_blank" rel="noopener noreferrer">   Terms of Service</a></p>
        <p>  <a href="https://vipme-app.com/privacy-policy/" target="_blank" rel="noopener noreferrer"> Privacy Policy</a></p>
        <p>  <a href="https://vipme-app.com/" target="_blank" rel="noopener noreferrer">Vip-me website</a></p>
      </div>
      {/* <div className="social-icons"> */}
      {/* <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
        <FacebookSharpIcon />
      </a>
      <a href="https://www.example.com" target="_blank" rel="noopener noreferrer">
        <XIcon />
      </a>
      <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
        <YouTubeIcon />
      </a> */}
      {/* <a href="https://www.instagram.com/vip.meapp/" target="_blank" rel="noopener noreferrer">
        <InstagramIcon />
      </a> */}
      {/* <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
        <LinkedInIcon />
      </a>
      <a href="https://www.pinterest.com" target="_blank" rel="noopener noreferrer">
        <PinterestIcon />
      </a> */}
      {/* <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer">
      <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 1000 1000"><path d="M906.25 0H93.75C42.19 0 0 42.19 0 93.75v812.49c0 51.57 42.19 93.75 93.75 93.75l812.5.01c51.56 0 93.75-42.19 93.75-93.75V93.75C1000 42.19 957.81 0 906.25 0zM684.02 319.72c-32.42-21.13-55.81-54.96-63.11-94.38-1.57-8.51-2.45-17.28-2.45-26.25H515l-.17 414.65c-1.74 46.43-39.96 83.7-86.8 83.7-14.57 0-28.27-3.63-40.35-9.99-27.68-14.57-46.63-43.58-46.63-76.97 0-47.96 39.02-86.98 86.97-86.98 8.95 0 17.54 1.48 25.66 4.01V421.89c-8.41-1.15-16.95-1.86-25.66-1.86-105.01 0-190.43 85.43-190.43 190.45 0 64.42 32.18 121.44 81.3 155.92 30.93 21.72 68.57 34.51 109.14 34.51 105.01 0 190.43-85.43 190.43-190.43V400.21c40.58 29.12 90.3 46.28 143.95 46.28V343.03c-28.89 0-55.8-8.59-78.39-23.31z"/></svg>
      </a> */}
    {/* </div> */}
    </footer>
  );
};
export default Footer;