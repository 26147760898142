import React, { useState, useEffect } from 'react';
import APIServices from '../../sevices/APIServices';
import { useSelector } from 'react-redux';
import './StripeSuccess.css';

const StripSucccess = () => {
  const userDetails = useSelector((state) => {
    return state.userDetails;
  });
  const eventdata = useSelector((state) => {
    return state.eventData;
  });
  const amountDetails = useSelector((state) => {
    return state.amountBody;
  });

  const ticketsData = useSelector((state) => {
    return state.ticketDetails;
  });
  // const amountDetails = JSON.parse(localStorage.getItem("amountBody") || '{}')

  // const eventdata = JSON.parse(localStorage.getItem("eventData") || '{}')

  // const ticketsData = JSON.parse(localStorage.getItem("ticketDetails") || '[]')
  const [showLoader, setShowLoader] = useState(false)
  useEffect(() => {
    APIServices.sendDataTobackend(ticketsData)
      .then((response) => {
        setShowLoader(false);

      })
      .catch((err) => {
        console.log("Error fetching event details: ", err);
        setShowLoader(false);
      });
    const payPayload = {
      customerMobile: userDetails.mobile,
      amount: amountDetails.amount,
      orderId: ';12345'

    }

    APIServices.pay(payPayload)
      .then((response) => {
        setShowLoader(false);
      })
      .catch((err) => {
        console.log("Error fetching event details: ", err);
        setShowLoader(false);
      });
    const bookticket = {
      eventId: eventdata?._id,
      ticketQuantity: ticketsData?.length
    }
    APIServices.bookTicket(bookticket)
      .then((response) => {
        setShowLoader(false);
      })
      .catch((err) => {
        console.log("Error fetching event details: ", err);
        setShowLoader(false);
      });
    const data = {
      docId: userDetails._id,
    };

    APIServices.reservationPoints(data)
      .then((response) => {
        setShowLoader(false);
      })
      .catch((err) => {
        console.log("Error fetching event details: ", err);
        setShowLoader(false);
      });
  }, [])
  return (
    <div className="stripe-success">
      <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%;' }}>
        <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4ryokv-Tc-YU9Nv8c5lLH9jBkC5t2pXYOSg&s' alt="Success Image" style={{ width: "30%" }} />
      </div>
      <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%;', fontSize: '25px;', marginTop: '15%;' }}>
        Payment was successful, please access your ticket in reservations</div>
    </div>
  );
}


// useEffect(async () => {
//   setShowLoader(true); // Start showing the loader
//   const payPayload ={
//     customerMobile:userDetails.mobile,
//     amount:amountDetails.amount,
//     orderId:';12345'

//   }
//   const bookticket = {
//     eventId:  ticketsData[0]._id,
//     ticketQuantity:  ticketsData[0]?.quantity
//   }
//    APIServices.sendDataTobackend(ticketsData);
// await APIServices.pay(payPayload);
//  await APIServices.bookTicket(bookticket);
//  await APIServices.reservationPoints({ docId: userDetails._id });

//   // Use Promise.all to wait for all promises to resolve
//   // Promise.all([sendDataPromise, payPromise, bookTicketPromise, reservationPointsPromise])
//   //   .then((responses) => {
//   //     console.log("All API calls were successful:", responses);
//   //     // Handle successful responses here if needed
//   //     setShowLoader(false); // Stop showing the loader
//   //   })
//   //   .catch((err) => {
//   //     console.error("Error occurred during one or more API calls:", err);
//   //     // Handle errors here if needed
//   //     setShowLoader(false); // Stop showing the loader
//   //   });
// }, []);
export default StripSucccess;


