import React, { useState } from "react";
import './searchevent.css';
import { useNavigate } from "react-router-dom";
const Eventsearch = () => {
    const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [events, setEvents] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const onSearch = async (event) => {
    setSearchTerm(event.target.value);

  if (searchTerm.length >= 2) {
      try {
        const response = await fetch(
          `https://vipmeapp.com/restaurantRouter/webeventsearch/${searchTerm}`,
          {
            method: "GET",

          }
        );
        const result = await response.json();
        console.log(result)
        setEvents(result);
        setShowDropdown(true);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    } else {
      setEvents([]);
      setShowDropdown(false);
    }
  };

  const handleSelectEvent = (event) => {
    setSearchTerm(event.eventName);
    setShowDropdown(false);
    navigate(`/eventoverview/${event.eventId}`)
    // You can handle further actions like navigating to the event's detail page
  };
 
  return (

    
    <div className="event-search-container">
           <div className="search-name"><span>Search events</span></div>
      <form className="search-form">
        <input
          type="text"
          value={searchTerm}
          onChange={onSearch}
          placeholder="Search events"
          className="search-input"
        />
      </form>

      {showDropdown && events.length > 0 && (
  <div className="autocomplete-dropdown">
    {events.map((event, index) => {
      // Filter only image URLs (assuming images have extensions like .jpg, .png, etc.)
      const imageUrls = event.allimages.filter(url => 
        url.match(/\.(jpeg|jpg|gif|png)$/)
      );

      return (
        <div
          key={index}
          className="autocomplete-item"
          onClick={() => handleSelectEvent(event)}
        >
          {imageUrls.length > 0 && (
            <img src={imageUrls[0]} alt='event' className="autocomplete-image" />
          )}
          <div className="autocomplete-details">
            <span className="autocomplete-title">{event.eventName}</span>
            {/* <span className="autocomplete-subtitle">@{event.eventName}</span> */}
          </div>
        </div>
      );
    })}
  </div>
)}

      {searchTerm.length >= 2 && events.length === 0 && (
        <p className="no-results">No results found</p>
      )}
    </div>
  );
};

export default Eventsearch;
