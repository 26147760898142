// import { configureStore } from '@reduxjs/toolkit'
// import { reducerFunction } from './Redux/Reducers';
// import {persistStore, persistReducer} from 'redux-persist';
// import storage from 'redux-persist/lib/storage';


// const persistConfig = {
//   key: 'root',
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, reducerFunction);

// export const store = configureStore({
//   reducer: persistedReducer,
// })

// // export default store;
// export const persistor = persistStore(store)
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { reducerFunction } from './Redux/Reducers';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducerFunction);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
        ignoredPaths: ['_persist'], // Ignore the _persist key that redux-persist adds to the state
      },
    }),
});

export const persistor = persistStore(store);