

// import React, { useRef, useState } from 'react';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import './VideoCard.css';
// import { useNavigate } from "react-router-dom";
// import { Carousel } from "react-bootstrap";
// import ReactPlayer from "react-player";
// import "bootstrap/dist/css/bootstrap.css";

// const VideoCard = ({ eventData }) => {
//   const [currentVideoIndex, setCurrentVideoIndex] = useState(null);
//   const [isPlaying, setIsPlaying] = useState(false);
//   const videoRefs = useRef([]);
//   const navigate = useNavigate();
//   const gotoEvent = (data) => {
//     localStorage.setItem('event', JSON.stringify(data))
//     navigate('/eventoverview')

//   }
//   const isVideo = (file) => {
//     return file.endsWith('.mp4') || file.endsWith('.webm') || file.endsWith('.ogg');
//   };
//   function convertTo12HourFormat(time) {
//     let [hours, minutes] = time.split(":");
//     hours = parseInt(hours, 10);
//     const ampm = hours >= 12 ? 'PM' : 'AM';
//     hours = hours % 12 || 12; 
//     return `${hours}:${minutes} ${ampm}`;
//   }
//   const renderMedia = (url) => {
//     const isVideo = url.endsWith('.mp4') || url.endsWith('.mov'); 
//     return isVideo ? (
//       <ReactPlayer
//         url={url}
//         playing
//         loop
//         muted
//         width="100%"
//         height="100%"
//         className="video-player"
//       />
//     ) : (
//       <img src={url} alt="Event" className="media-player" />
//     );
//   };



//   const playVideo = (index) => {
//     const videoElement = videoRefs.current[index];

//     if (currentVideoIndex === index) {
//       if (isPlaying) {
//         videoElement.pause();
//         setIsPlaying(false);
//       } else {
//         videoElement.play();
//         setIsPlaying(true);
//       }
//     } else {
//       if (currentVideoIndex !== null) {
//         const prevVideo = videoRefs.current[currentVideoIndex];
//         if (prevVideo) {
//           prevVideo.pause();
//           prevVideo.currentTime = 0; 
//         }
//       }
//       if (videoElement) {
//         videoElement.play();
//         videoElement.muted = false; 
//         setIsPlaying(true);
//         setCurrentVideoIndex(index);
//       }
//     }
//   };
//   return (

//     <div className="video-card-carousel">
//       <Carousel>
//         {eventData?.map((event) => (
//           <Carousel.Item key={event?._id}>
//             <div className="video-wrapper">
//               {event?.allimages?.map((videoUrl, index) => (
//                 <div key={index} className="media-container">
//                   {renderMedia(videoUrl)}
//                 </div>
//               ))}
//               <div className="video-overlay">
//                 <div className="closing-time">
//                   Closes at: {convertTo12HourFormat(event?.endTime)}
//                 </div>
//                 <div className="video-details">
//                   <h3 className="title">{event?.eventName}</h3>
//                   <div className="location">{event?.location}</div>
//                   <div className="button">
//                     <button className="details-button" onClick={() => gotoEvent(event)}>See Details</button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </Carousel.Item>
//         ))}
//       </Carousel>
//     </div>
// )};
// export default VideoCard;

import React, { useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import ReactPlayer from "react-player";
import "bootstrap/dist/css/bootstrap.css";
import './VideoCard.css';

const VideoCard = ({ eventData }) => {
  const [playingIndex, setPlayingIndex] = useState(null);
  const [isMuted, setIsMuted] = useState(true);
  const navigate = useNavigate();

  const gotoEvent = (data) => {
    navigate(`/eventoverview/${data.eventId}`)

  }

  const isVideo = (file) => {
    return file.endsWith('.mp4') || file.endsWith('.webm') || file.endsWith('.ogg') ||file.endsWith('.mov');
  };

  // Handle video play/pause and mute/unmute when clicking the speaker icon
  const handleVideoPlay = (index) => {
    if (playingIndex !== index) {
      setPlayingIndex(index); // Set the currently playing video
      setIsMuted(false); // Unmute the video
    } else {
      setIsMuted(!isMuted); // Toggle mute state if the same video is clicked again
    }
  };

  const convertTo12HourFormat = (time) => {
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours, 10);
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${ampm}`;
  };

  // const handleSpeakerClick = (index) => {
  //   setPlayingIndex(index);
  //   setIsMuted(!isMuted);
  // };
  const handleSpeakerClick = (index) => {
    if (playingIndex === index) {
      // Toggle sound on the same video
      setIsMuted(!isMuted);
    } else {
      // Switch to a different video
      setPlayingIndex(index);
      setIsMuted(false); // Unmute the newly selected video
    }
  };

  const handleSlideChange = () => {
    // Mute all videos and reset playing index on slide change
    // setPlayingIndex(null);
    setIsMuted(true);
  };
  const renderMedia = (url, index) => {
    const isVideo = url.endsWith('.mp4') || url.endsWith('.webm') || url.endsWith('.ogg') ||url.endsWith('.mov');
    return isVideo ? (
      <><ReactPlayer
        url={url}
        playing={true}
        loop
        muted={playingIndex !== index || isMuted}
        width="100%"
        height="100%"
        playsinline  
        controls={false}
        style={{ objectFit: 'cover' }}
        className="video-player" /><SpeakerIcon index={index} /></>
    ) : (
      <img src={url} alt="Event" className="media-player" />
    );
  };
  const SpeakerIcon = ({ index }) => (
    <div className="speaker-icon" onClick={() => handleVideoPlay(index)}>
      <i className={isMuted || playingIndex !== index ? 'muted-icon' : 'unmuted-icon'}></i>
    </div>
  );
  return (
    <div className="video-card-carousel">
      <Carousel style={{width:'100%',objectFit:'cover'}} onSlid={handleSlideChange}>
        {eventData?.map((event, index) => {
          const mediaUrl = event?.allimages[0]; 
          return (
            <Carousel.Item key={event?._id} >
              <div className="item-container">
                <div className="media-container">
                  {renderMedia(mediaUrl, index)}
                  {isVideo(mediaUrl) && (
                    <div 
                      className="speaker-icon" 
                      onClick={() => handleSpeakerClick(index)}
                    >
                      {playingIndex === index && !isMuted ? '🔊' : '🔇'}
                    </div>
                  )}
                </div>
                <div className="overlay">
                  <div className="end-time">
                    Closes at: {convertTo12HourFormat(event?.endTime || "00:00")}
                  </div>
                  <div className='button-center'>
                  <h3 className="title">{event?.eventName}</h3>
                  <div className="location">{event?.location}</div>
                
                     <button className="details-button" onClick={() => gotoEvent(event)}>See Details</button>
                     </div>
                 
                </div>
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};

export default VideoCard;






