import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import './SearchInput.css'
import { Backdrop, CircularProgress } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import { setUserLat, setUserLocation } from '../../Redux/Actions';
import { setUserLong } from '../../Redux/Actions';
const SearchInput = ({ placeholderText }) => {
    const userDetails = useSelector((state) => state.userDetails);
    const userLoactionName = useSelector((state) => state.userLocation);
    const dispatch = useDispatch();
    const [showLoader, setShowLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [searchQuery, setSearchQuery] = useState(userLoactionName !== null ? userLoactionName : '');
    // const [selectedCoordinates, setSelectedCoordinates] = useState({ latitude: null, longitude: null });
    const [isCountrySearch, setIsCountrySearch] = useState(false);

    useEffect(() => {
        setSearchQuery(userLoactionName)
    }, [userLoactionName])

    const searchInput = useRef(null);
    // console.log("selectedCoordinates: ", selectedCoordinates);

    const getContinent = (lat, lng) => {
        if (lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180) {
            if ((lat >= 15 && lat <= 72) && (lng >= -170 && lng <= -30)) return 'North America';
            if ((lat >= -56 && lat <= 15) && (lng >= -82 && lng <= -35)) return 'South America';
            if ((lat >= 35 && lat <= 72) && (lng >= -30 && lng <= 60)) return 'Europe';
            if ((lat >= -35 && lat <= 35) && (lng >= -18 && lng <= 51)) return 'Africa';
            if ((lat >= 10 && lat <= 81) && (lng >= 26 && lng <= 180)) return 'Asia';
            if ((lat >= -50 && lat <= 0) && (lng >= 110 && lng <= 180)) return 'Australia';
            if (lat < -60) return 'Antarctica';

            return 'Unknown';
        }

        return 'Invalid coordinates';
    };

    const constructCustomAddress = (addressComponents) => {
        const excludedTypes = ['street_number', 'route'];
        let customAddress = '';

        addressComponents.forEach(component => {
            if (!excludedTypes.some(type => component.types.includes(type)) && (component.types.includes('locality') || component.types.includes('administrative_area_level_1') || component.types.includes('country'))) {
                customAddress += component.long_name + ', ';
            }
        });
        return customAddress.slice(0, -2);
    };

    const handleSearch = () => {
        setShowLoader(false);

        const inputText = searchInput?.current?.value;

        if (inputText === "") {
            setErrorMessage('Search for the place you want');
            return;
        }

        setShowLoader(true);
        const autocompleteService = new window.google.maps.places.AutocompleteService();

        autocompleteService.getPlacePredictions({ input: inputText, language: 'en' }, (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions && predictions.length > 0) {
                const firstPrediction = predictions[0];
                const description = firstPrediction?.description;
                const placeService = new window.google.maps.places.PlacesService(document.createElement("div"));

                placeService.getDetails({ placeId: firstPrediction?.place_id, language: 'en' }, (place, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        let locationName = description;

                        if (locationName.includes('+') || locationName.match(/^\d+[-\d]*, /)) {
                            locationName = constructCustomAddress(place.address_components);
                        }

                        if (locationName) {
                            const { location } = place.geometry;

                            const coordinatesOfPlace = {
                                latitude: location?.lat(),
                                longitude: location?.lng(),
                            };
                            dispatch(setUserLat(coordinatesOfPlace.latitude));
                            dispatch(setUserLong(coordinatesOfPlace.longitude));
                            setIsCountrySearch(!locationName?.includes(','));
                            setSearchQuery(locationName);
                            // console.log("Coordinates: ", coordinatesOfPlace); // Log the coordinates
                            setShowLoader(false);
                        }
                    }
                });
            }
        });
    };

    const initAutocomplete = () => {
        if (!searchInput?.current || !window?.google || !window?.google?.maps || !window?.google?.maps?.places) {
            return;
        }

        const autocomplete = new window.google.maps.places.Autocomplete(searchInput?.current, {
            language: 'en',
        });
        autocomplete.setFields(["place_id", "address_components", "geometry", "formatted_address"]);

        autocomplete.addListener("place_changed", () => {
            const place = autocomplete?.getPlace();

            if (!place?.geometry || !place?.geometry?.location) {
                console.error("Place geometry or location is undefined");
                return;
            }

            const inputText = searchInput?.current?.value;
            dispatch(setUserLocation(inputText))
            setShowLoader(true);
            const autocompleteService = new window.google.maps.places.AutocompleteService();

            autocompleteService.getPlacePredictions({ input: inputText, language: 'en' }, (predictions, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
                    const selectedPrediction = predictions.find(prediction => prediction.place_id === place.place_id) || predictions[0];
                    const locationName = selectedPrediction?.description;

                    if (locationName) {
                        const { location } = place.geometry;
                        const coordinatesOfPlace = {
                            latitude: location?.lat(),
                            longitude: location?.lng(),
                        };
                        // console.log("coordinatesOfPlace.latitude: ", coordinatesOfPlace.latitude)
                        dispatch(setUserLat(coordinatesOfPlace.latitude));
                        dispatch(setUserLong(coordinatesOfPlace.longitude));
                        // setSelectedCoordinates(coordinatesOfPlace);
                        setIsCountrySearch(!locationName?.includes(','));
                        setSearchQuery(locationName);
                        console.log("Coordinates: ", coordinatesOfPlace);
                        setShowLoader(false);

                    }
                }
            });
        });

        searchInput?.current?.addEventListener("keydown", (event) => {
            if (event.key === "Enter") {
                event.preventDefault();
                handleSearch();
            }
        });

        return () => {
            searchInput?.current?.removeEventListener("keydown");
        };
    };

    useEffect(() => {
        initAutocomplete();
    }, []);

    return (
        <div className='sai-search'>
            <Backdrop sx={{
                color: "#ffffff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }} open={showLoader}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div style={{}} className='seach-component-container'>

                <div className="search-container">
                    <input
                        type="text"
                        ref={searchInput}
                        placeholder={placeholderText}
                        value={searchQuery}
                        onChange={(event) => setSearchQuery(event.target.value)}
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                event.preventDefault();
                                handleSearch();
                            }
                        }}
                        className="search-input"
                    />
                    <SearchIcon
                        onClick={handleSearch}
                        className="search-icon"
                    />
                </div>
                <div style={{ height: '20px' }}>
                    {errorMessage && (
                        <p className="error-message" style={{ color: 'red', fontSize: '12px', marginTop: '1px' }}>
                            {errorMessage}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SearchInput;
